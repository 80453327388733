import { getCallable } from "apis/_sdk/client";
import type {
    GetPlayerMetadataRequest,
    GetPlayerMetadataResponse,
    GetPlayerContextRequest,
    GetPlayerContextResponse,
    CheckPlayerPasswordRequest,
    CheckPlayerPasswordResponse,
    ReportPlayerSalesforceEventRequest,
    ReportPlayerSalesforceEventResponse,
    QueryPlayerAnalayticsRequest,
    QueryPlayerAnalayticsResponse,
    TrackPlayerAnalyticsRequest,
    TrackPlayerAnalyticsResponse,
    GetOfflinePlayerDownloadUrlRequest,
    GetOfflinePlayerDownloadUrlResponse,
} from "./types";
import { endpoints, apiName } from "./endpoints";

const getPlayerMetadata = getCallable<GetPlayerMetadataRequest, GetPlayerMetadataResponse>(apiName, "getPlayerMetadata", {
    ...endpoints.getPlayerMetadata,
    composer: request => {
        const search = new URLSearchParams();
        if (request.embedSessionId) {
            search.set("embedSessionId", request.embedSessionId);
        }

        return {
            url: `/player/metadata/${encodeURIComponent(request.id)}?${search.toString()}`,
            options: {
                method: "GET"
            }
        };
    }
});

const getPlayerContext = getCallable<GetPlayerContextRequest, GetPlayerContextResponse>(apiName, "getPlayerContext", {
    ...endpoints.getPlayerContext,
    composer: request => {
        return {
            url: `/player/context/${encodeURIComponent(request.id)}${request.isZoomApp ? "?isZoomApp=true" : ""}`,
            options: {
                method: "GET"
            }
        };
    }
});

const checkPlayerPassword = getCallable<CheckPlayerPasswordRequest, CheckPlayerPasswordResponse>(apiName, "checkPlayerPassword", {
    ...endpoints.checkPlayerPassword,
    composer: request => {
        return {
            url: "/player/password",
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const reportPlayerSalesforceEvent = getCallable<ReportPlayerSalesforceEventRequest, ReportPlayerSalesforceEventResponse>(apiName, "reportPlayerSalesforceEvent", {
    ...endpoints.reportPlayerSalesforceEvent,
    composer: request => {
        return {
            url: "/player/salesforce-progress",
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const queryPlayerAnalytics = getCallable<QueryPlayerAnalayticsRequest, QueryPlayerAnalayticsResponse>(apiName, "queryPlayerAnalytics", {
    ...endpoints.queryPlayerAnalytics,
    composer: request => {
        return {
            url: "/player/analytics-query",
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const trackPlayerAnalytics = getCallable<TrackPlayerAnalyticsRequest, TrackPlayerAnalyticsResponse>(apiName, "trackPlayerAnalytics", {
    ...endpoints.trackPlayerAnalytics,
    composer: request => {
        return {
            url: "/player/analytics-track",
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const getOfflinePlayerDownloadUrl = getCallable<GetOfflinePlayerDownloadUrlRequest, GetOfflinePlayerDownloadUrlResponse>(apiName, "getOfflinePlayerDownloadUrl", {
    ...endpoints.getOfflinePlayerDownloadUrl,
    composer: request => {
        return {
            url: "/player/offline-data?os=" + encodeURIComponent(request.os),
            options: {
                method: "GET"
            }
        };
    }
});

export const callables = {
    getPlayerMetadata,
    getPlayerContext,
    checkPlayerPassword,
    reportPlayerSalesforceEvent,
    queryPlayerAnalytics,
    trackPlayerAnalytics,
    getOfflinePlayerDownloadUrl,
};
