import lodash from "lodash";

import { getCallable } from "../_sdk/client";
import {
    GetPresentationRequest, GetPresentationResponse,
    CreatePresentationRequest, CreatePresentationResponse,
    UpdatePresentationRequest, UpdatePresentationResponse,
    DeletePresentationRequest, DeletePresentationResponse,
    CopyPresentationRequest, CopyPresentationResponse,
    MoveWorkspaceRequest, MoveWorkspaceResponse,
    RecordActivityRequest, RecordActivityResponse,
    GetSlidesMetadataRequest, GetSlidesMetadataResponse,
    UpdateSlidesMetadataRequest, UpdateSlidesMetadataResponse,
    CreateLinkRequest, CreateLinkResponse,
    UpdateLinkPasswordRequest, UpdateLinkPasswordResponse,
    ConnectEmbedLinkDomainsRequest, ConnectEmbedLinkDomainsResponse,
    IncrementLinkViewCountRequest, IncrementLinkViewCountResponse,
    GetPresentationsMetadataRequest, GetPresentationsMetadataResponse,
    GetSlideCountRequest, GetSlideCountResponse,
} from "./types";
import { endpoints, apiName } from "./endpoints";

const getPresentation = getCallable<GetPresentationRequest, GetPresentationResponse>(apiName, "getPresentation", {
    ...endpoints.getPresentation,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}`,
            options: {
                method: "GET"
            }
        };
    }
});

const createPresentation = getCallable<CreatePresentationRequest, CreatePresentationResponse>(apiName, "createPresentation", {
    ...endpoints.createPresentation,
    composer: request => {
        return {
            url: "/presentations",
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
});

const updatePresentation = getCallable<UpdatePresentationRequest, UpdatePresentationResponse>(apiName, "updatePresentation", {
    ...endpoints.updatePresentation,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const deletePresentation = getCallable<DeletePresentationRequest, DeletePresentationResponse>(apiName, "deletePresentation", {
    ...endpoints.deletePresentation,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}`,
            options: {
                method: "DELETE"
            }
        };
    }
});

const copyPresentation = getCallable<CopyPresentationRequest, CopyPresentationResponse>(apiName, "copyPresentation", {
    ...endpoints.copyPresentation,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/copy`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const moveWorkspace = getCallable<MoveWorkspaceRequest, MoveWorkspaceResponse>(apiName, "moveWorkspace", {
    ...endpoints.moveWorkspace,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/move`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const recordActivity = getCallable<RecordActivityRequest, RecordActivityResponse>(apiName, "recordActivity", {
    ...endpoints.recordActivity,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/activity`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const getSlidesMetadata = getCallable<GetSlidesMetadataRequest, GetSlidesMetadataResponse>(apiName, "getSlidesMetadata", {
    ...endpoints.getMetadata,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/slidesMetadata`,
            options: {
                method: "GET"
            }
        };
    }
});

const updateSlidesMetadata = getCallable<UpdateSlidesMetadataRequest, UpdateSlidesMetadataResponse>(apiName, "updateSlidesMetadata", {
    ...endpoints.updateMetadata,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/slidesMetadata`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const createLink = getCallable<CreateLinkRequest, CreateLinkResponse>(apiName, "createLink", {
    ...endpoints.createLink,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/links`,
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
});

const updateLinkPassword = getCallable<UpdateLinkPasswordRequest, UpdateLinkPasswordResponse>(apiName, "updateLinkPassword", {
    ...endpoints.updateLinkPassword,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/links/${encodeURIComponent(request.linkId)}/password`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const connectEmbedLinkDomains = getCallable<ConnectEmbedLinkDomainsRequest, ConnectEmbedLinkDomainsResponse>(apiName, "connectEmbedLinkDomains", {
    ...endpoints.connectEmbedLinkDomains,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/links/${encodeURIComponent(request.linkId)}/embedLinkDomains`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        }
    }
});

const incrementLinkViewCount = getCallable<IncrementLinkViewCountRequest, IncrementLinkViewCountResponse>(apiName, "incrementLinkViewCount", {
    ...endpoints.incrementLinkViewCount,
    composer: request => {
        return {
            url: `/presentations/${encodeURIComponent(request.id)}/links/${encodeURIComponent(request.linkId)}/viewCount`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const getPresentationsMetadata = getCallable<GetPresentationsMetadataRequest, GetPresentationsMetadataResponse>(apiName, "getPresentationsMetadata", {
    ...endpoints.getPresentationsMetadata,
    composer: request => {
        const chunks = lodash.chunk(request.presentationIds, 500);
        return chunks.map(presentationIds => ({
            url: "/presentations/metadata",
            options: {
                method: "POST",
                body: JSON.stringify({ presentationIds })
            }
        }));
    },
    collector: responses => {
        return responses.flat();
    }
});

const getSlideCount = getCallable<GetSlideCountRequest, GetSlideCountResponse>(apiName, "getSlideCount", {
    ...endpoints.getSlideCount,
    composer: request => {
        return {
            url: `/presentations/slideCount`,
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
});

export const callables = {
    getPresentation,
    createPresentation,
    updatePresentation,
    deletePresentation,

    copyPresentation,
    moveWorkspace,
    recordActivity,

    getSlidesMetadata,
    updateSlidesMetadata,

    createLink,
    updateLinkPassword,
    connectEmbedLinkDomains,
    incrementLinkViewCount,

    getPresentationsMetadata,

    getSlideCount
};
