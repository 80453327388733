import React, { Component } from "react";
import { DialogTitle, DialogActions, Button, TextField, Typography } from "@material-ui/core";
import { ColGrid } from "js/react/components/LayoutGrid";
import {
    BeautifulDialog,
    DialogContent
} from "js/react/components/Dialogs/BaseDialog";
import { Gap10 } from "js/react/components/Gap";
import CheckBox from "js/react/components/CheckBox";
import linkDS from "js/react/views/PresentationSettings/dataservices/LinkDataService";
import { trackActivity } from "js/core/utilities/utilities";
import { ToggleAllowCommenting } from "../components/ToggleAllowCommenting";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import getLogger from "js/core/logger";
import { ShowSnackBar } from "js/react/components/Dialogs/SnackBar";
import { PresentationPrivacyType } from "common/constants";
import { domainRegex } from "js/core/utilities/regex";
import isValidDomain from "is-valid-domain";

const logger = getLogger();

export default class CreateEmbedCodeDialog extends Component {
    static get type() {
        return "EmbedCode";
    }

    constructor(props) {
        super(props);

        this.state = {
            useCustomSize: false,
            width: 640,
            height: 360,
            accepted: false,
            fetching: true,
            allowCommenting: false,
            domainWhitelist: "",
            domainValidationError: null,
            saveError: null
        };
        this.embedRef = React.createRef();
    }

    get isSecured() {
        return this.props.presentation.getPrivacySetting() === PresentationPrivacyType.SECURED;
    }

    handleAccept = () => {
        const embedOptions = {
            width: this.state.width,
            height: this.state.height,
            useCustomSize: this.state.useCustomSize,
        };
        const allowCommenting = this.state.allowCommenting;

        let domainWhitelist = this.state.domainWhitelist;

        if (this.isSecured && !domainWhitelist) {
            this.setState({ saveError: "Secured presentations requires domain whitelist to embed." });
            return;
        }

        if (domainWhitelist) {
            const saveError = this.validateDomainWhitelist();
            if (saveError) {
                this.setState({ saveError });
                return;
            }
            domainWhitelist = domainWhitelist.trim().replace(/\s/g, "");
        }

        linkDS.updateLink(this.link, {
            embedOptions,
            allowCommenting,
            domainWhitelist,
        });
        linkDS.saveLink(this.props.presentation, this.link).then(() => {
            this.copyToClipboard();
            ShowSnackBar({
                message: "Embed code was copied to the clipboard"
            });
            this.setState({ accepted: true });
            this.props.closeDialog();
            const props = {
                presentation_id: this.props.presentation.id,
                link_ids: [this.link.get("id")],
                is_new: true,
                type: "embed_code",
                workspace_id: this.props.presentation.getWorkspaceId()
            };
            trackActivity("Sharing", "CopyLink", null, null, props, { audit: true });
            this.props.callback();
        });
    };

    componentDidMount() {
        linkDS.createEmbedLink(this.props.presentation)
            .then(link => {
                this.link = link;
                this.setState({ fetching: false });
            })
            .catch(err => {
                logger.error(err, "linkDS.createEmbedLink() failed", { presentationId: this.props.presentation.id });
            });
    }

    componentWillUnmount() {
        if (!this.state.accepted && this.link) {
            this.link.destroy();
        }
    }

    copyToClipboard() {
        const embedCode = this.props.presentation.getEmbedCode(this.link, this.state);
        clipboardWrite(
            {
                [ClipboardType.HTML]: embedCode,
                [ClipboardType.TEXT]: embedCode,
            },
            true,
        );
    }

    handleCustomSize(value, type) {
        if (type == "width") {
            this.setState({
                width: value,
                height: value * 0.5625
            });
        } else {
            this.setState({
                height: value,
                width: value * 1.7777777778
            });
        }
    }

    toggleAllowCommenting = event => {
        const allowCommenting = !this.state.allowCommenting;
        this.setState({ allowCommenting });
    }

    validateDomainWhitelist() {
        const domainWhitelist = this.state.domainWhitelist.trim().replace(/\s/g, "").split(",");

        if (domainWhitelist.some(domain => !domainRegex.test(domain) || !isValidDomain(domain))) {
            return "Invalid domain.";
        }
    }

    render() {
        const { fetching, allowCommenting, domainWhitelist, saveError } = this.state;

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>{this.isSecured ? "Create Secured Embed Code" : "Create Embed Code"}</DialogTitle>
                <DialogContent className="dialog-embed">
                    <h3>
                        Create and copy a snippet of html code to embed this presentation into a website or blog.
                        {this.isSecured ? " Whitelist a trusted domain and this embed will only play on that domain." : ""}
                    </h3>
                    <Gap10 />
                    <ToggleAllowCommenting
                        allowCommenting={allowCommenting}
                        onToggle={this.toggleAllowCommenting}
                    />
                    {this.isSecured && <>
                        <Gap10 />
                        <TextField
                            id="domainWhitelist"
                            label="Domain Whitelist"
                            placeholder="beautiful.ai"
                            value={domainWhitelist}
                            type="text"
                            className="textField"
                            fullWidth
                            onChange={event =>
                                this.setState({ domainWhitelist: event.target.value })
                            }
                            onBlur={event => {
                                const saveError = this.validateDomainWhitelist();
                                this.setState({ saveError });
                            }}
                        />
                        {saveError &&
                            <Typography variant="caption" color="error">
                                {saveError}
                            </Typography>}
                    </>}
                    <Gap10 />
                    <ColGrid>
                        <CheckBox
                            checked={this.state.useCustomSize}
                            value="useCustomSize"
                            label="Use Custom Size"
                            onChange={value =>
                                this.setState({ useCustomSize: value })
                            }
                        />
                        {this.state.useCustomSize &&
                            <>
                                <TextField
                                    id="width"
                                    label="Width"
                                    placeholder="auto"
                                    value={this.state.width}
                                    type="number"
                                    className="textField"
                                    onChange={event =>
                                        this.handleCustomSize(event.target.value, "width")
                                    }
                                />
                                <TextField
                                    id="height"
                                    label="Height"
                                    placeholder="auto"
                                    value={this.state.height}
                                    type="number"
                                    className="textField"
                                    onChange={event =>
                                        this.handleCustomSize(event.target.value, "height")
                                    }
                                />
                            </>
                        }
                    </ColGrid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.handleAccept} disabled={fetching || (this.isSecured && !domainWhitelist)} color="primary">
                        Copy Embed Code to Clipboard
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
