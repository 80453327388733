const theme = {
    isBuiltIn: true,
    id: "ember",
    name: "Ember",
    cjkFont: "jp",
    colors: {
        accent1: "rgba(210,38,47,1)",
        accent2: "rgba(21,57,105,1)",
        accent3: "rgba(0,112,125,1)",
        accent4: "rgba(102,102,102,1)",
        background_accent: "rgba(245,245,245,1)",
        background_dark: "rgba(0,0,0,1)",
        background_light: "#ffffff",
        chart1: "rgba(250,123,0,1)",
        chart2: "rgba(210,38,47,1)",
        chart3: "rgba(21,57,105,1)",
        chart4: "rgba(0,112,125,1)",
        chart5: "rgba(102,102,102,1)",
        hyperlink: "#11a9e2",
        negative: "rgba(210,38,47,1)",
        positive: "rgba(0,112,125,1)",
        primary_dark: "rgba(51,51,51,1)",
        primary_light: "rgba(255,255,255,1)",
        secondary_dark: "rgba(0,0,0,0.65)",
        secondary_light: "rgba(255,255,255,0.8)",
        theme: "rgba(250,123,0,1)"
    },
    defaultBackgroundColor: "background_light",
    defaultSlideColor: "theme",
    fontBodyFontId: "dmsans",
    fontBodyLetterSpacing: 0.02,
    fontBodyLineHeight: 1.9,
    fontBodyScaling: 100,
    fontBodyTextTransform: "auto",
    fontBodyWeight: 400,
    fontHeaderFontId: "dmsans",
    fontHeaderLetterSpacing: -0.06,
    fontHeaderLineHeight: 1.4,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontHeaderWeight: 700,
    fontScale: 1,
    fontTitleFontId: "dmsans",
    fontTitleLetterSpacing: -0.03,
    fontTitleLineHeight: 1.4,
    fontTitleScaling: 100,
    fontTitleTextTransform: "auto",
    fontTitleWeight: 700,
    footerMessage: `${new Date().getFullYear()} Proprietary and Confidential. All Rights Reserved.`,
    iconStyle: "classic",
    isRTL: false,
    logoOffset: 0,
    logoPosition: "left",
    logoScale: 1,
    palette_name: "custom",
    showFooterByDefault: true,
    showLogo: true,
    showMessage: false,
    showPageNum: true,
    styleBackground: "none",
    styleBodyFont: "sourcesanspro",
    styleDecoration: "none_left",
    styleDesign: 1,
    styleEffect: "none",
    styleElementStyle: "filled",
    styleFontWeight: "heavy",
    styleShape: "circle",
    styleTitleFont: "bebasneue",
    styleTitleSlide: "bar_left",
    styleWeight: "light"
};

module.exports = theme;
