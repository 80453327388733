import moment from "moment";
import StorageModel from "../storage/storageModel";
import ReferenceCollection from "js/core/storage/referenceCollection";
import { LinkType } from "js/react/views/PresentationSettings/dataservices/LinkDataService";
import { presentations as presentationsApi } from "apis/callables";
import { app } from "js/namespaces";

const PresentationLink = StorageModel.extend({
    root: "presentationLinks",

    getLinkURL: function() {
        let customPlayerURLTemplate = null;
        try {
            customPlayerURLTemplate = app.appController.currentTeam?.get("customPlayerURLTemplate");
        } catch {
            // An error means no customPlayerURLTemplate value
        }
        if (customPlayerURLTemplate) {
            return customPlayerURLTemplate.replace("${linkId}", this.id);
        } else {
            switch (this.get("type")) {
                case LinkType.MEETING:
                    return `${window.location.origin}/meeting/${this.id}`;
                case LinkType.EMBED:
                    return `${window.location.origin}/embed/${this.id}`;
                default:
                    return `${window.location.origin}/player/${this.id}`;
            }
        }
    },

    // checks if a link has an expiration set
    shouldLinkExpire() {
        return !!this.get("expiresAt");
    },

    // checks if this link is expired or not
    isLinkExpired() {
        return this.isExpirationBeforeDate(new Date());
    },

    // checks if this link has expired or not
    isExpirationBeforeDate(date) {
        const expiresAt = this.get("expiresAt");

        // not set to expire
        if (!expiresAt) {
            return false;
        }

        // check if this falls before now
        const expires = moment(expiresAt);
        return expires.isBefore(date);
    },

    getInviteContactName: function() {
        let contact = this.get("email");
        if (typeof (contact) == "object") {
            return contact.name;
        } else {
            return contact;
        }
    },

    update: function(attrs, options) {
        // add `modifiedAt` on every update (if the update actually changes anything)
        let changeSet = StorageModel.prototype.update.call(this, attrs, Object.assign({ computeChangeSet: true }, options));
        if (changeSet.hasUpdates) {
            attrs = Object.assign({ modifiedAt: new Date().getTime() }, attrs);
        }
        return StorageModel.prototype.update.call(this, attrs, options);
    },

    updatePassword: async function(password) {
        if (this.get("type") !== LinkType.PROTECTED) return;

        await presentationsApi.updateLinkPassword({
            id: this.get("presentationId"), linkId: this.id, password
        });
    },

    connectEmbedDomains: async function(domainWhitelist) {
        if (this.get("type") !== LinkType.EMBED) return;

        await presentationsApi.connectEmbedLinkDomains({
            id: this.get("presentationId"), linkId: this.id, domainWhitelist
        });
    },

    getLinkName: function() {
        switch (this.get("type")) {
            case LinkType.INVITE:
                return this.getInviteContactName();
            case LinkType.SOCIAL:
                return this.get("name").toTitleCase();
            default:
                return this.get("name");
        }
    }
});

const PresentationLinks = ReferenceCollection.extend({
    model: PresentationLink,
    ignoreErrors: true,
    referenceRoot: "presentations",

    getReferenceId: function() {
        return `${this.presentationId}/links`;
    },

    initialize: function(models, options) {
        this.type = "PresentationLinks";
        this.presentationId = options.presentationId;
    },

    createLink: async function(model) {
        // Create a new link using the api
        const { id } = await presentationsApi.createLink({
            id: this.presentationId, ...model
        });
        // Instantiate and load the model
        const linkModel = new PresentationLink({ id });
        await linkModel.load();
        return linkModel;
    },

    addLink(link) {
        return this.add(link);
    },

    deleteLink: function(id) {
        let link = this.get(id);
        if (link) {
            return this.remove(link).then(() => {
                link.destroy();
            });
        } else {
            return Promise.reject(new Error("No link to delete."));
        }
    }

});

export { PresentationLink, PresentationLinks };
