const theme = {
    isBuiltIn: true,
    id: "spritz",
    name: "Spritz",
    cjkFont: "jp",
    colors: {
        accent1: "rgba(255,97,237,1)",
        accent2: "rgba(255,205,26,1)",
        accent3: "rgba(155,153,255,1)",
        accent4: "rgba(255,140,26,1)",
        background_accent: "rgba(34,34,34,1)",
        background_dark: "rgba(10,10,10,1)",
        background_light: "#ffffff",
        chart1: "rgba(148,230,255,1)",
        chart2: "rgba(255,97,237,1)",
        chart3: "rgba(255,205,26,1)",
        chart4: "rgba(155,153,255,1)",
        chart5: "rgba(255,140,26,1)",
        hyperlink: "#11a9e2",
        negative: "rgba(255,140,26,1)",
        positive: "rgba(169,221,60,1)",
        primary_dark: "rgba(51,51,51,1)",
        primary_light: "rgba(255,255,255,1)",
        secondary_dark: "rgba(0,0,0,0.55)",
        secondary_light: "rgba(255,255,255,0.8)",
        theme: "rgba(148,230,255,1)"
    },
    defaultBackgroundColor: "background_dark",
    defaultSlideColor: "theme",
    fontBodyFontId: "epilogue",
    fontBodyLetterSpacing: 0,
    fontBodyLineHeight: 1.9,
    fontBodyScaling: 100,
    fontBodyTextTransform: "auto",
    fontBodyWeight: 400,
    fontHeaderFontId: "epilogue",
    fontHeaderLetterSpacing: -0.05,
    fontHeaderLineHeight: 1.4,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontHeaderWeight: 800,
    fontScale: 1,
    fontTitleFontId: "epilogue",
    fontTitleLetterSpacing: -0.06,
    fontTitleLineHeight: 1.6,
    fontTitleScaling: 100,
    fontTitleTextTransform: "auto",
    fontTitleWeight: 800,
    footerMessage: `${new Date().getFullYear()} Proprietary and Confidential. All Rights Reserved.`,
    iconStyle: "classic",
    isRTL: false,
    logoOffset: 0,
    logoPosition: "left",
    logoScale: 1,
    palette_name: "custom",
    showFooterByDefault: true,
    showLogo: true,
    showMessage: false,
    showPageNum: true,
    styleBackground: "none",
    styleBodyFont: "sourcesanspro",
    styleDecoration: "none_left",
    styleDesign: 1,
    styleEffect: "none",
    styleElementStyle: "filled",
    styleFontWeight: "heavy",
    styleShape: "circle",
    styleTitleFont: "bebasneue",
    styleTitleSlide: "bar_left",
    styleWeight: "light"
};

module.exports = theme;
