const builtInFonts = require("./builtInFonts.json");
const cjkFallbackFonts = require("./cjkFallbackFonts.json");

const builtInFontFiles = {
    "Jost-Light": "Jost/Jost-300-Light.ttf",
    "Jost-Regular": "Jost/Jost-500-Medium.ttf",
    "Jost-SemiBold": "Jost/Jost-600-Semi.ttf",
    "Jost-Black": "Jost/Jost-900-Black.ttf",

    "Jost-LightItalic": "Jost/Jost-300-LightItalic.ttf",
    "Jost-RegularItalic": "Jost/Jost-500-MediumItalic.ttf",
    "Jost-SemiBoldItalic": "Jost/Jost-600-SemiItalic.ttf",
    "Jost-BlackItalic": "Jost/Jost-900-BlackItalic.ttf",

    "Lato-Light": "Lato/Lato-Light.ttf",
    "Lato-Regular": "Lato/Lato-Regular.ttf",
    "Lato-Bold": "Lato/Lato-Bold.ttf",
    "Lato-Black": "Lato/Lato-Black.ttf",

    "Lato-LightItalic": "Lato/Lato-LightItalic.ttf",
    "Lato-Italic": "Lato/Lato-Italic.ttf",
    "Lato-BoldItalic": "Lato/Lato-BoldItalic.ttf",
    "Lato-BlackItalic": "Lato/Lato-BlackItalic.ttf",

    "Poppins-Light": "Poppins/Poppins-Light.ttf",
    "Poppins-Regular": "Poppins/Poppins-Regular.ttf",
    "Poppins-Bold": "Poppins/Poppins-Bold.ttf",
    "Poppins-Black": "Poppins/Poppins-Black.ttf",

    "Poppins-LightItalic": "Poppins/Poppins-LightItalic.ttf",
    "Poppins-Italic": "Poppins/Poppins-Italic.ttf",
    "Poppins-BoldItalic": "Poppins/Poppins-BoldItalic.ttf",
    "Poppins-BlackItalic": "Poppins/Poppins-BlackItalic.ttf",

    "SpaceMono-Regular": "SpaceMono/SpaceMono-Regular.ttf",
    "SpaceMono-Bold": "SpaceMono/SpaceMono-Bold.ttf",
    "SpaceMono-Italic": "SpaceMono/SpaceMono-Italic.ttf",
    "SpaceMono-BoldItalic": "SpaceMono/SpaceMono-BoldItalic.ttf",

    "Trocchi": "Trocchi/Trocchi-Regular.ttf",

    "SourceSansPro-Light": "SourceSansPro/SourceSansPro-Light.ttf",
    "SourceSansPro-Regular": "SourceSansPro/SourceSansPro-Regular.ttf",
    "SourceSansPro-SemiBold": "SourceSansPro/SourceSansPro-SemiBold.ttf",
    "SourceSansPro-Black": "SourceSansPro/SourceSansPro-Black.ttf",

    "SourceSansPro-LightItalic": "SourceSansPro/SourceSansPro-LightItalic.ttf",
    "SourceSansPro-Italic": "SourceSansPro/SourceSansPro-Italic.ttf",
    "SourceSansPro-SemiBoldItalic": "SourceSansPro/SourceSansPro-SemiBoldItalic.ttf",
    "SourceSansPro-BlackItalic": "SourceSansPro/SourceSansPro-BlackItalic.ttf",

    "BebasNeue Book": "BebasNeue/BebasNeue Book.otf",
    "BebasNeue Regular": "BebasNeue/BebasNeue Regular.otf",
    "BebasNeue Bold": "BebasNeue/BebasNeue Bold.otf",

    "RobotoSlab-Thin": "RobotoSlab/RobotoSlab-Thin.ttf",
    "RobotoSlab-Light": "RobotoSlab/RobotoSlab-Light.ttf",
    "RobotoSlab-Regular": "RobotoSlab/RobotoSlab-Regular.ttf",
    "RobotoSlab-Bold": "RobotoSlab/RobotoSlab-Bold.ttf",

    "Roboto-Light": "Roboto/Roboto-Light.ttf",
    "Roboto-Regular": "Roboto/Roboto-Regular.ttf",
    "Roboto-Bold": "Roboto/Roboto-Bold.ttf",
    "Roboto-Black": "Roboto/Roboto-Black.ttf",

    "Roboto-LightItalic": "Roboto/Roboto-LightItalic.ttf",
    "Roboto-Italic": "Roboto/Roboto-Italic.ttf",
    "Roboto-BoldItalic": "Roboto/Roboto-BoldItalic.ttf",
    "Roboto-BlackItalic": "Roboto/Roboto-BlackItalic.ttf",

    "Marker": "Marker/TragicMarker.otf",

    "PlayfairDisplay-Regular": "PlayfairDisplay/PlayfairDisplay-Regular.ttf",
    "PlayfairDisplay-Black": "PlayfairDisplay/PlayfairDisplay-Black.ttf",

    "PlayfairDisplay-Italic": "PlayfairDisplay/PlayfairDisplay-Italic.ttf",
    "PlayfairDisplay-BlackItalic": "PlayfairDisplay/PlayfairDisplay-BlackItalic.ttf",

    "Raleway-Regular": "Raleway/Raleway-Regular.ttf",
    "Raleway-Medium": "Raleway/Raleway-Medium.ttf",
    "Raleway-SemiBold": "Raleway/Raleway-SemiBold.ttf",
    "Raleway-Bold": "Raleway/Raleway-Bold.ttf",
    "Raleway-ExtraBold": "Raleway/Raleway-ExtraBold.ttf",
    "Raleway-Black": "Raleway/Raleway-Black.ttf",

    "Raleway-Italic": "Raleway/Raleway-Italic.ttf",
    "Raleway-MediumItalic": "Raleway/Raleway-MediumItalic.ttf",
    "Raleway-SemiBoldItalic": "Raleway/Raleway-SemiBoldItalic.ttf",
    "Raleway-BoldItalic": "Raleway/Raleway-BoldItalic.ttf",
    "Raleway-ExtraBoldItalic": "Raleway/Raleway-ExtraBoldItalic.ttf",
    "Raleway-BlackItalic": "Raleway/Raleway-BlackItalic.ttf",

    "Montserrat-Thin": "Montserrat/Montserrat-Thin.ttf",
    "Montserrat-ExtraLight": "Montserrat/Montserrat-ExtraLight.ttf",
    "Montserrat-Light": "Montserrat/Montserrat-Light.ttf",
    "Montserrat-Regular": "Montserrat/Montserrat-Regular.ttf",
    "Montserrat-Medium": "Montserrat/Montserrat-Medium.ttf",
    "Montserrat-SemiBold": "Montserrat/Montserrat-SemiBold.ttf",
    "Montserrat-Bold": "Montserrat/Montserrat-Bold.ttf",
    "Montserrat-ExtraBold": "Montserrat/Montserrat-ExtraBold.ttf",
    "Montserrat-Black": "Montserrat/Montserrat-Black.ttf",

    "Montserrat-ThinItalic": "Montserrat/Montserrat-ThinItalic.ttf",
    "Montserrat-ExtraLightItalic": "Montserrat/Montserrat-ExtraLightItalic.ttf",
    "Montserrat-LightItalic": "Montserrat/Montserrat-LightItalic.ttf",
    "Montserrat-Italic": "Montserrat/Montserrat-Italic.ttf",
    "Montserrat-MediumItalic": "Montserrat/Montserrat-MediumItalic.ttf",
    "Montserrat-SemiBoldItalic": "Montserrat/Montserrat-SemiBoldItalic.ttf",
    "Montserrat-BoldItalic": "Montserrat/Montserrat-BoldItalic.ttf",
    "Montserrat-ExtraBoldItalic": "Montserrat/Montserrat-ExtraBoldItalic.ttf",
    "Montserrat-BlackItalic": "Montserrat/Montserrat-BlackItalic.ttf",

    "Carlito-Regular": "Carlito/Carlito-Regular.ttf",
    "Carlito-RegularItalic": "Carlito/Carlito-RegularItalic.ttf",
    "Carlito-BoldItalic": "Carlito/Carlito-BoldItalic.ttf",
    "Carlito-Bold": "Carlito/Carlito-Bold.ttf",

    "Archivo-ExtraLight": "Archivo/Archivo-ExtraLight.ttf",
    "Archivo-ExtraLightItalic": "Archivo/Archivo-ExtraLightItalic.ttf",
    "Archivo-Light": "Archivo/Archivo-Light.ttf",
    "Archivo-LightItalic": "Archivo/Archivo-LightItalic.ttf",
    "Archivo-Regular": "Archivo/Archivo-Regular.ttf",
    "Archivo-Italic": "Archivo/Archivo-Italic.ttf",
    "Archivo-Medium": "Archivo/Archivo-Medium.ttf",
    "Archivo-MediumItalic": "Archivo/Archivo-MediumItalic.ttf",
    "Archivo-SemiBold": "Archivo/Archivo-SemiBold.ttf",
    "Archivo-SemiBoldItalic": "Archivo/Archivo-SemiBoldItalic.ttf",
    "Archivo-Bold": "Archivo/Archivo-Bold.ttf",
    "Archivo-BoldItalic": "Archivo/Archivo-BoldItalic.ttf",
    "Archivo-ExtraBold": "Archivo/Archivo-ExtraBold.ttf",
    "Archivo-ExtraBoldItalic": "Archivo/Archivo-ExtraBoldItalic.ttf",
    "Archivo-Black": "Archivo/Archivo-Black.ttf",
    "Archivo-BlackItalic": "Archivo/Archivo-BlackItalic.ttf",

    "BespokeSlab-Light": "BespokeSlab/BespokeSlab-Light.otf",
    "BespokeSlab-LightItalic": "BespokeSlab/BespokeSlab-LightItalic.otf",
    "BespokeSlab-Regular": "BespokeSlab/BespokeSlab-Regular.otf",
    "BespokeSlab-Italic": "BespokeSlab/BespokeSlab-Italic.otf",
    "BespokeSlab-Medium": "BespokeSlab/BespokeSlab-Medium.otf",
    "BespokeSlab-MediumItalic": "BespokeSlab/BespokeSlab-MediumItalic.otf",
    "BespokeSlab-Bold": "BespokeSlab/BespokeSlab-Bold.otf",
    "BespokeSlab-BoldItalic": "BespokeSlab/BespokeSlab-BoldItalic.otf",
    "BespokeSlab-ExtraBold": "BespokeSlab/BespokeSlab-ExtraBold.otf",
    "BespokeSlab-ExtraBoldItalic": "BespokeSlab/BespokeSlab-ExtraBoldItalic.otf",

    "BigShouldersDisplay-ExtraLight": "BigShouldersDisplay/BigShouldersDisplay-ExtraLight.ttf",
    "BigShouldersDisplay-Light": "BigShouldersDisplay/BigShouldersDisplay-Light.ttf",
    "BigShouldersDisplay-Regular": "BigShouldersDisplay/BigShouldersDisplay-Regular.ttf",
    "BigShouldersDisplay-Medium": "BigShouldersDisplay/BigShouldersDisplay-Medium.ttf",
    "BigShouldersDisplay-SemiBold": "BigShouldersDisplay/BigShouldersDisplay-SemiBold.ttf",
    "BigShouldersDisplay-Bold": "BigShouldersDisplay/BigShouldersDisplay-Bold.ttf",
    "BigShouldersDisplay-ExtraBold": "BigShouldersDisplay/BigShouldersDisplay-ExtraBold.ttf",
    "BigShouldersDisplay-Black": "BigShouldersDisplay/BigShouldersDisplay-Black.ttf",

    "Bitter-ExtraLight": "Bitter/Bitter-ExtraLight.ttf",
    "Bitter-ExtraLightItalic": "Bitter/Bitter-ExtraLightItalic.ttf",
    "Bitter-Light": "Bitter/Bitter-Light.ttf",
    "Bitter-LightItalic": "Bitter/Bitter-LightItalic.ttf",
    "Bitter-Regular": "Bitter/Bitter-Regular.ttf",
    "Bitter-Italic": "Bitter/Bitter-Italic.ttf",
    "Bitter-Medium": "Bitter/Bitter-Medium.ttf",
    "Bitter-MediumItalic": "Bitter/Bitter-MediumItalic.ttf",
    "Bitter-SemiBold": "Bitter/Bitter-SemiBold.ttf",
    "Bitter-SemiBoldItalic": "Bitter/Bitter-SemiBoldItalic.ttf",
    "Bitter-Bold": "Bitter/Bitter-Bold.ttf",
    "Bitter-BoldItalic": "Bitter/Bitter-BoldItalic.ttf",
    "Bitter-ExtraBold": "Bitter/Bitter-ExtraBold.ttf",
    "Bitter-ExtraBoldItalic": "Bitter/Bitter-ExtraBoldItalic.ttf",
    "Bitter-Black": "Bitter/Bitter-Black.ttf",
    "Bitter-BlackItalic": "Bitter/Bitter-BlackItalic.ttf",

    "BricolageGrotesque-ExtraLight": "BricolageGrotesque/BricolageGrotesque-ExtraLight.ttf",
    "BricolageGrotesque-Light": "BricolageGrotesque/BricolageGrotesque-Light.ttf",
    "BricolageGrotesque-Regular": "BricolageGrotesque/BricolageGrotesque-Regular.ttf",
    "BricolageGrotesque-Medium": "BricolageGrotesque/BricolageGrotesque-Medium.ttf",
    "BricolageGrotesque-SemiBold": "BricolageGrotesque/BricolageGrotesque-SemiBold.ttf",
    "BricolageGrotesque-Bold": "BricolageGrotesque/BricolageGrotesque-Bold.ttf",
    "BricolageGrotesque-ExtraBold": "BricolageGrotesque/BricolageGrotesque-ExtraBold.ttf",

    "Catamaran-ExtraLight": "Catamaran/Catamaran-ExtraLight.ttf",
    "Catamaran-Light": "Catamaran/Catamaran-Light.ttf",
    "Catamaran-Regular": "Catamaran/Catamaran-Regular.ttf",
    "Catamaran-Medium": "Catamaran/Catamaran-Medium.ttf",
    "Catamaran-SemiBold": "Catamaran/Catamaran-SemiBold.ttf",
    "Catamaran-Bold": "Catamaran/Catamaran-Bold.ttf",
    "Catamaran-ExtraBold": "Catamaran/Catamaran-ExtraBold.ttf",
    "Catamaran-Black": "Catamaran/Catamaran-Black.ttf",

    "ClashDisplay-ExtraLight": "ClashDisplay/ClashDisplay-ExtraLight.otf",
    "ClashDisplay-Light": "ClashDisplay/ClashDisplay-Light.otf",
    "ClashDisplay-Regular": "ClashDisplay/ClashDisplay-Regular.otf",
    "ClashDisplay-Medium": "ClashDisplay/ClashDisplay-Medium.otf",
    "ClashDisplay-SemiBold": "ClashDisplay/ClashDisplay-SemiBold.otf",
    "ClashDisplay-Bold": "ClashDisplay/ClashDisplay-Bold.otf",

    "Cormorant-Light": "Cormorant/Cormorant-Light.ttf",
    "Cormorant-LightItalic": "Cormorant/Cormorant-LightItalic.ttf",
    "Cormorant-Regular": "Cormorant/Cormorant-Regular.ttf",
    "Cormorant-Italic": "Cormorant/Cormorant-Italic.ttf",
    "Cormorant-Medium": "Cormorant/Cormorant-Medium.ttf",
    "Cormorant-MediumItalic": "Cormorant/Cormorant-MediumItalic.ttf",
    "Cormorant-SemiBold": "Cormorant/Cormorant-SemiBold.ttf",
    "Cormorant-SemiBoldItalic": "Cormorant/Cormorant-SemiBoldItalic.ttf",
    "Cormorant-Bold": "Cormorant/Cormorant-Bold.ttf",
    "Cormorant-BoldItalic": "Cormorant/Cormorant-BoldItalic.ttf",

    "DarkerGrotesque-Light": "DarkerGrotesque/DarkerGrotesque-Light.ttf",
    "DarkerGrotesque-Regular": "DarkerGrotesque/DarkerGrotesque-Regular.ttf",
    "DarkerGrotesque-Medium": "DarkerGrotesque/DarkerGrotesque-Medium.ttf",
    "DarkerGrotesque-SemiBold": "DarkerGrotesque/DarkerGrotesque-SemiBold.ttf",
    "DarkerGrotesque-Bold": "DarkerGrotesque/DarkerGrotesque-Bold.ttf",
    "DarkerGrotesque-ExtraBold": "DarkerGrotesque/DarkerGrotesque-ExtraBold.ttf",
    "DarkerGrotesque-Black": "DarkerGrotesque/DarkerGrotesque-Black.ttf",

    "DelaGothicOne-Regular": "DelaGothicOne/DelaGothicOne-Regular.ttf",

    "DMMono-Light": "DMMono/DMMono-Light.ttf",
    "DMMono-LightItalic": "DMMono/DMMono-LightItalic.ttf",
    "DMMono-Regular": "DMMono/DMMono-Regular.ttf",
    "DMMono-Italic": "DMMono/DMMono-Italic.ttf",
    "DMMono-Medium": "DMMono/DMMono-Medium.ttf",
    "DMMono-MediumItalic": "DMMono/DMMono-MediumItalic.ttf",

    "DMSans-ExtraLight": "DMSans/DMSans-ExtraLight.ttf",
    "DMSans-ExtraLightItalic": "DMSans/DMSans-ExtraLightItalic.ttf",
    "DMSans-Light": "DMSans/DMSans-Light.ttf",
    "DMSans-LightItalic": "DMSans/DMSans-LightItalic.ttf",
    "DMSans-Regular": "DMSans/DMSans-Regular.ttf",
    "DMSans-Italic": "DMSans/DMSans-Italic.ttf",
    "DMSans-Medium": "DMSans/DMSans-Medium.ttf",
    "DMSans-MediumItalic": "DMSans/DMSans-MediumItalic.ttf",
    "DMSans-SemiBold": "DMSans/DMSans-SemiBold.ttf",
    "DMSans-SemiBoldItalic": "DMSans/DMSans-SemiBoldItalic.ttf",
    "DMSans-Bold": "DMSans/DMSans-Bold.ttf",
    "DMSans-BoldItalic": "DMSans/DMSans-BoldItalic.ttf",
    "DMSans-ExtraBold": "DMSans/DMSans-ExtraBold.ttf",
    "DMSans-ExtraBoldItalic": "DMSans/DMSans-ExtraBoldItalic.ttf",
    "DMSans-Black": "DMSans/DMSans-Black.ttf",
    "DMSans-BlackItalic": "DMSans/DMSans-BlackItalic.ttf",

    "Domine-Regular": "Domine/Domine-Regular.ttf",
    "Domine-Medium": "Domine/Domine-Medium.ttf",
    "Domine-SemiBold": "Domine/Domine-SemiBold.ttf",
    "Domine-Bold": "Domine/Domine-Bold.ttf",

    "EduVICWANTBeginner-Regular": "EduVICWANTBeginner/EduVICWANTBeginner-Regular.ttf",
    "EduVICWANTBeginner-Medium": "EduVICWANTBeginner/EduVICWANTBeginner-Medium.ttf",
    "EduVICWANTBeginner-SemiBold": "EduVICWANTBeginner/EduVICWANTBeginner-SemiBold.ttf",
    "EduVICWANTBeginner-Bold": "EduVICWANTBeginner/EduVICWANTBeginner-Bold.ttf",

    "Epilogue-ExtraLight": "Epilogue/Epilogue-ExtraLight.ttf",
    "Epilogue-ExtraLightItalic": "Epilogue/Epilogue-ExtraLightItalic.ttf",
    "Epilogue-Light": "Epilogue/Epilogue-Light.ttf",
    "Epilogue-LightItalic": "Epilogue/Epilogue-LightItalic.ttf",
    "Epilogue-Regular": "Epilogue/Epilogue-Regular.ttf",
    "Epilogue-Italic": "Epilogue/Epilogue-Italic.ttf",
    "Epilogue-Medium": "Epilogue/Epilogue-Medium.ttf",
    "Epilogue-MediumItalic": "Epilogue/Epilogue-MediumItalic.ttf",
    "Epilogue-SemiBold": "Epilogue/Epilogue-SemiBold.ttf",
    "Epilogue-SemiBoldItalic": "Epilogue/Epilogue-SemiBoldItalic.ttf",
    "Epilogue-Bold": "Epilogue/Epilogue-Bold.ttf",
    "Epilogue-BoldItalic": "Epilogue/Epilogue-BoldItalic.ttf",
    "Epilogue-ExtraBold": "Epilogue/Epilogue-ExtraBold.ttf",
    "Epilogue-ExtraBoldItalic": "Epilogue/Epilogue-ExtraBoldItalic.ttf",
    "Epilogue-Black": "Epilogue/Epilogue-Black.ttf",
    "Epilogue-BlackItalic": "Epilogue/Epilogue-BlackItalic.ttf",

    "Gayathri-Thin": "Gayathri/Gayathri-Thin.ttf",
    "Gayathri-Regular": "Gayathri/Gayathri-Regular.ttf",
    "Gayathri-Bold": "Gayathri/Gayathri-Bold.ttf",

    "Geist-Thin": "Geist/Geist-Thin.otf",
    "Geist-ExtraLight": "Geist/Geist-ExtraLight.otf",
    "Geist-Light": "Geist/Geist-Light.otf",
    "Geist-Regular": "Geist/Geist-Regular.otf",
    "Geist-Medium": "Geist/Geist-Medium.otf",
    "Geist-SemiBold": "Geist/Geist-SemiBold.otf",
    "Geist-Bold": "Geist/Geist-Bold.otf",
    "Geist-ExtraBold": "Geist/Geist-ExtraBold.otf",
    "Geist-Black": "Geist/Geist-Black.otf",

    "GeneralSans-ExtraLight": "GeneralSans/GeneralSans-ExtraLight.otf",
    "GeneralSans-ExtraLightItalic": "GeneralSans/GeneralSans-ExtraLightItalic.otf",
    "GeneralSans-Light": "GeneralSans/GeneralSans-Light.otf",
    "GeneralSans-LightItalic": "GeneralSans/GeneralSans-LightItalic.otf",
    "GeneralSans-Regular": "GeneralSans/GeneralSans-Regular.otf",
    "GeneralSans-Italic": "GeneralSans/GeneralSans-Italic.otf",
    "GeneralSans-Medium": "GeneralSans/GeneralSans-Medium.otf",
    "GeneralSans-MediumItalic": "GeneralSans/GeneralSans-MediumItalic.otf",
    "GeneralSans-SemiBold": "GeneralSans/GeneralSans-SemiBold.otf",
    "GeneralSans-SemiBoldItalic": "GeneralSans/GeneralSans-SemiBoldItalic.otf",
    "GeneralSans-Bold": "GeneralSans/GeneralSans-Bold.otf",
    "GeneralSans-BoldItalic": "GeneralSans/GeneralSans-BoldItalic.otf",

    "Glegoo-Regular": "Glegoo/Glegoo-Regular.ttf",
    "Glegoo-Bold": "Glegoo/Glegoo-Bold.ttf",

    "IBMPlexSans-ExtraLight": "IBMPlexSans/IBMPlexSans-ExtraLight.ttf",
    "IBMPlexSans-ExtraLightItalic": "IBMPlexSans/IBMPlexSans-ExtraLightItalic.ttf",
    "IBMPlexSans-Light": "IBMPlexSans/IBMPlexSans-Light.ttf",
    "IBMPlexSans-LightItalic": "IBMPlexSans/IBMPlexSans-LightItalic.ttf",
    "IBMPlexSans-Regular": "IBMPlexSans/IBMPlexSans-Regular.ttf",
    "IBMPlexSans-Italic": "IBMPlexSans/IBMPlexSans-Italic.ttf",
    "IBMPlexSans-Medium": "IBMPlexSans/IBMPlexSans-Medium.ttf",
    "IBMPlexSans-MediumItalic": "IBMPlexSans/IBMPlexSans-MediumItalic.ttf",
    "IBMPlexSans-SemiBold": "IBMPlexSans/IBMPlexSans-SemiBold.ttf",
    "IBMPlexSans-SemiBoldItalic": "IBMPlexSans/IBMPlexSans-SemiBoldItalic.ttf",
    "IBMPlexSans-Bold": "IBMPlexSans/IBMPlexSans-Bold.ttf",
    "IBMPlexSans-BoldItalic": "IBMPlexSans/IBMPlexSans-BoldItalic.ttf",

    "IBMPlexSansCondensed-Thin": "IBMPlexSansCondensed/IBMPlexSansCondensed-Thin.ttf",
    "IBMPlexSansCondensed-ThinItalic": "IBMPlexSansCondensed/IBMPlexSansCondensed-ThinItalic.ttf",
    "IBMPlexSansCondensed-ExtraLight": "IBMPlexSansCondensed/IBMPlexSansCondensed-ExtraLight.ttf",
    "IBMPlexSansCondensed-ExtraLightItalic": "IBMPlexSansCondensed/IBMPlexSansCondensed-ExtraLightItalic.ttf",
    "IBMPlexSansCondensed-Light": "IBMPlexSansCondensed/IBMPlexSansCondensed-Light.ttf",
    "IBMPlexSansCondensed-LightItalic": "IBMPlexSansCondensed/IBMPlexSansCondensed-LightItalic.ttf",
    "IBMPlexSansCondensed-Regular": "IBMPlexSansCondensed/IBMPlexSansCondensed-Regular.ttf",
    "IBMPlexSansCondensed-Italic": "IBMPlexSansCondensed/IBMPlexSansCondensed-Italic.ttf",
    "IBMPlexSansCondensed-Medium": "IBMPlexSansCondensed/IBMPlexSansCondensed-Medium.ttf",
    "IBMPlexSansCondensed-MediumItalic": "IBMPlexSansCondensed/IBMPlexSansCondensed-MediumItalic.ttf",
    "IBMPlexSansCondensed-SemiBold": "IBMPlexSansCondensed/IBMPlexSansCondensed-SemiBold.ttf",
    "IBMPlexSansCondensed-SemiBoldItalic": "IBMPlexSansCondensed/IBMPlexSansCondensed-SemiBoldItalic.ttf",
    "IBMPlexSansCondensed-Bold": "IBMPlexSansCondensed/IBMPlexSansCondensed-Bold.ttf",
    "IBMPlexSansCondensed-BoldItalic": "IBMPlexSansCondensed/IBMPlexSansCondensed-BoldItalic.ttf",

    "InstrumentSans-Regular": "InstrumentSans/InstrumentSans-Regular.ttf",
    "InstrumentSans-Italic": "InstrumentSans/InstrumentSans-Italic.ttf",
    "InstrumentSans-Medium": "InstrumentSans/InstrumentSans-Medium.ttf",
    "InstrumentSans-MediumItalic": "InstrumentSans/InstrumentSans-MediumItalic.ttf",
    "InstrumentSans-SemiBold": "InstrumentSans/InstrumentSans-SemiBold.ttf",
    "InstrumentSans-SemiBoldItalic": "InstrumentSans/InstrumentSans-SemiBoldItalic.ttf",
    "InstrumentSans-Bold": "InstrumentSans/InstrumentSans-Bold.ttf",
    "InstrumentSans-BoldItalic": "InstrumentSans/InstrumentSans-BoldItalic.ttf",

    "Inter-ExtraLight": "Inter/Inter-ExtraLight.ttf",
    "Inter-ExtraLightItalic": "Inter/Inter-ExtraLightItalic.ttf",
    "Inter-Light": "Inter/Inter-Light.ttf",
    "Inter-LightItalic": "Inter/Inter-LightItalic.ttf",
    "Inter-Regular": "Inter/Inter-Regular.ttf",
    "Inter-Italic": "Inter/Inter-Italic.ttf",
    "Inter-Medium": "Inter/Inter-Medium.ttf",
    "Inter-MediumItalic": "Inter/Inter-MediumItalic.ttf",
    "Inter-SemiBold": "Inter/Inter-SemiBold.ttf",
    "Inter-SemiBoldItalic": "Inter/Inter-SemiBoldItalic.ttf",
    "Inter-Bold": "Inter/Inter-Bold.ttf",
    "Inter-BoldItalic": "Inter/Inter-BoldItalic.ttf",
    "Inter-ExtraBold": "Inter/Inter-ExtraBold.ttf",
    "Inter-ExtraBoldItalic": "Inter/Inter-ExtraBoldItalic.ttf",
    "Inter-Black": "Inter/Inter-Black.ttf",
    "Inter-BlackItalic": "Inter/Inter-BlackItalic.ttf",

    "Lexend-ExtraLight": "Lexend/Lexend-ExtraLight.ttf",
    "Lexend-Light": "Lexend/Lexend-Light.ttf",
    "Lexend-Regular": "Lexend/Lexend-Regular.ttf",
    "Lexend-Medium": "Lexend/Lexend-Medium.ttf",
    "Lexend-SemiBold": "Lexend/Lexend-SemiBold.ttf",
    "Lexend-Bold": "Lexend/Lexend-Bold.ttf",
    "Lexend-ExtraBold": "Lexend/Lexend-ExtraBold.ttf",
    "Lexend-Black": "Lexend/Lexend-Black.ttf",

    "Literata-ExtraLight": "Literata/Literata-ExtraLight.otf",
    "Literata-ExtraLightItalic": "Literata/Literata-ExtraLightItalic.otf",
    "Literata-Light": "Literata/Literata-Light.otf",
    "Literata-LightItalic": "Literata/Literata-LightItalic.otf",
    "Literata-Regular": "Literata/Literata-Regular.otf",
    "Literata-Italic": "Literata/Literata-Italic.otf",
    "Literata-Medium": "Literata/Literata-Medium.otf",
    "Literata-MediumItalic": "Literata/Literata-MediumItalic.otf",
    "Literata-SemiBold": "Literata/Literata-SemiBold.otf",
    "Literata-SemiBoldItalic": "Literata/Literata-SemiBoldItalic.otf",
    "Literata-Bold": "Literata/Literata-Bold.otf",
    "Literata-BoldItalic": "Literata/Literata-BoldItalic.otf",
    "Literata-ExtraBold": "Literata/Literata-ExtraBold.otf",
    "Literata-ExtraBoldItalic": "Literata/Literata-ExtraBoldItalic.otf",
    "Literata-Black": "Literata/Literata-Black.otf",
    "Literata-BlackItalic": "Literata/Literata-BlackItalic.otf",

    "Mada-ExtraLight": "Mada/Mada-ExtraLight.ttf",
    "Mada-Light": "Mada/Mada-Light.ttf",
    "Mada-Regular": "Mada/Mada-Regular.ttf",
    "Mada-Medium": "Mada/Mada-Medium.ttf",
    "Mada-SemiBold": "Mada/Mada-SemiBold.ttf",
    "Mada-Bold": "Mada/Mada-Bold.ttf",
    "Mada-ExtraBold": "Mada/Mada-ExtraBold.ttf",
    "Mada-Black": "Mada/Mada-Black.ttf",

    "MerriweatherSans-Light": "MerriweatherSans/MerriweatherSans-Light.ttf",
    "MerriweatherSans-LightItalic": "MerriweatherSans/MerriweatherSans-LightItalic.ttf",
    "MerriweatherSans-Regular": "MerriweatherSans/MerriweatherSans-Regular.ttf",
    "MerriweatherSans-Italic": "MerriweatherSans/MerriweatherSans-Italic.ttf",
    "MerriweatherSans-Medium": "MerriweatherSans/MerriweatherSans-Medium.ttf",
    "MerriweatherSans-MediumItalic": "MerriweatherSans/MerriweatherSans-MediumItalic.ttf",
    "MerriweatherSans-SemiBold": "MerriweatherSans/MerriweatherSans-SemiBold.ttf",
    "MerriweatherSans-SemiBoldItalic": "MerriweatherSans/MerriweatherSans-SemiBoldItalic.ttf",
    "MerriweatherSans-Bold": "MerriweatherSans/MerriweatherSans-Bold.ttf",
    "MerriweatherSans-BoldItalic": "MerriweatherSans/MerriweatherSans-BoldItalic.ttf",
    "MerriweatherSans-ExtraBold": "MerriweatherSans/MerriweatherSans-ExtraBold.ttf",
    "MerriweatherSans-ExtraBoldItalic": "MerriweatherSans/MerriweatherSans-ExtraBoldItalic.ttf",

    "NotoSans-ExtraLight": "NotoSans/NotoSans-ExtraLight.ttf",
    "NotoSans-ExtraLightItalic": "NotoSans/NotoSans-ExtraLightItalic.ttf",
    "NotoSans-Light": "NotoSans/NotoSans-Light.ttf",
    "NotoSans-LightItalic": "NotoSans/NotoSans-LightItalic.ttf",
    "NotoSans-Regular": "NotoSans/NotoSans-Regular.ttf",
    "NotoSans-Italic": "NotoSans/NotoSans-Italic.ttf",
    "NotoSans-Medium": "NotoSans/NotoSans-Medium.ttf",
    "NotoSans-MediumItalic": "NotoSans/NotoSans-MediumItalic.ttf",
    "NotoSans-SemiBold": "NotoSans/NotoSans-SemiBold.ttf",
    "NotoSans-SemiBoldItalic": "NotoSans/NotoSans-SemiBoldItalic.ttf",
    "NotoSans-Bold": "NotoSans/NotoSans-Bold.ttf",
    "NotoSans-BoldItalic": "NotoSans/NotoSans-BoldItalic.ttf",
    "NotoSans-ExtraBold": "NotoSans/NotoSans-ExtraBold.ttf",
    "NotoSans-ExtraBoldItalic": "NotoSans/NotoSans-ExtraBoldItalic.ttf",
    "NotoSans-Black": "NotoSans/NotoSans-Black.ttf",
    "NotoSans-BlackItalic": "NotoSans/NotoSans-BlackItalic.ttf",

    "NunitoSans-ExtraLight": "NunitoSans/NunitoSans-ExtraLight.ttf",
    "NunitoSans-ExtraLightItalic": "NunitoSans/NunitoSans-ExtraLightItalic.ttf",
    "NunitoSans-Light": "NunitoSans/NunitoSans-Light.ttf",
    "NunitoSans-LightItalic": "NunitoSans/NunitoSans-LightItalic.ttf",
    "NunitoSans-Regular": "NunitoSans/NunitoSans-Regular.ttf",
    "NunitoSans-Italic": "NunitoSans/NunitoSans-Italic.ttf",
    "NunitoSans-Medium": "NunitoSans/NunitoSans-Medium.ttf",
    "NunitoSans-MediumItalic": "NunitoSans/NunitoSans-MediumItalic.ttf",
    "NunitoSans-SemiBold": "NunitoSans/NunitoSans-SemiBold.ttf",
    "NunitoSans-SemiBoldItalic": "NunitoSans/NunitoSans-SemiBoldItalic.ttf",
    "NunitoSans-Bold": "NunitoSans/NunitoSans-Bold.ttf",
    "NunitoSans-BoldItalic": "NunitoSans/NunitoSans-BoldItalic.ttf",
    "NunitoSans-ExtraBold": "NunitoSans/NunitoSans-ExtraBold.ttf",
    "NunitoSans-ExtraBoldItalic": "NunitoSans/NunitoSans-ExtraBoldItalic.ttf",
    "NunitoSans-Black": "NunitoSans/NunitoSans-Black.ttf",
    "NunitoSans-BlackItalic": "NunitoSans/NunitoSans-BlackItalic.ttf",

    "OpenSans-Regular": "OpenSans/OpenSans-Regular.ttf",
    "OpenSans-Italic": "OpenSans/OpenSans-Italic.ttf",
    "OpenSans-Medium": "OpenSans/OpenSans-Medium.ttf",
    "OpenSans-MediumItalic": "OpenSans/OpenSans-MediumItalic.ttf",
    "OpenSans-SemiBold": "OpenSans/OpenSans-SemiBold.ttf",
    "OpenSans-SemiBoldItalic": "OpenSans/OpenSans-SemiBoldItalic.ttf",
    "OpenSans-Bold": "OpenSans/OpenSans-Bold.ttf",
    "OpenSans-BoldItalic": "OpenSans/OpenSans-BoldItalic.ttf",
    "OpenSans-ExtraBold": "OpenSans/OpenSans-ExtraBold.ttf",
    "OpenSans-ExtraBoldItalic": "OpenSans/OpenSans-ExtraBoldItalic.ttf",

    "PathwayExtreme-ExtraLight": "PathwayExtreme/PathwayExtreme-ExtraLight.ttf",
    "PathwayExtreme-ExtraLightItalic": "PathwayExtreme/PathwayExtreme-ExtraLightItalic.ttf",
    "PathwayExtreme-Light": "PathwayExtreme/PathwayExtreme-Light.ttf",
    "PathwayExtreme-LightItalic": "PathwayExtreme/PathwayExtreme-LightItalic.ttf",
    "PathwayExtreme-Regular": "PathwayExtreme/PathwayExtreme-Regular.ttf",
    "PathwayExtreme-Italic": "PathwayExtreme/PathwayExtreme-Italic.ttf",
    "PathwayExtreme-Medium": "PathwayExtreme/PathwayExtreme-Medium.ttf",
    "PathwayExtreme-MediumItalic": "PathwayExtreme/PathwayExtreme-MediumItalic.ttf",
    "PathwayExtreme-SemiBold": "PathwayExtreme/PathwayExtreme-SemiBold.ttf",
    "PathwayExtreme-SemiBoldItalic": "PathwayExtreme/PathwayExtreme-SemiBoldItalic.ttf",
    "PathwayExtreme-Bold": "PathwayExtreme/PathwayExtreme-Bold.ttf",
    "PathwayExtreme-BoldItalic": "PathwayExtreme/PathwayExtreme-BoldItalic.ttf",
    "PathwayExtreme-ExtraBold": "PathwayExtreme/PathwayExtreme-ExtraBold.ttf",
    "PathwayExtreme-ExtraBoldItalic": "PathwayExtreme/PathwayExtreme-ExtraBoldItalic.ttf",
    "PathwayExtreme-Black": "PathwayExtreme/PathwayExtreme-Black.ttf",
    "PathwayExtreme-BlackItalic": "PathwayExtreme/PathwayExtreme-BlackItalic.ttf",

    "Plein-Light": "Plein/Plein-Light.otf",
    "Plein-LightItalic": "Plein/Plein-LightItalic.otf",
    "Plein-Regular": "Plein/Plein-Regular.otf",
    "Plein-Italic": "Plein/Plein-Italic.otf",
    "Plein-Medium": "Plein/Plein-Medium.otf",
    "Plein-MediumItalic": "Plein/Plein-MediumItalic.otf",
    "Plein-Bold": "Plein/Plein-Bold.otf",
    "Plein-BoldItalic": "Plein/Plein-BoldItalic.otf",
    "Plein-Black": "Plein/Plein-Black.otf",
    "Plein-BlackItalic": "Plein/Plein-BlackItalic.otf",

    "Quilon-Regular": "Quilon/Quilon-Regular.otf",
    "Quilon-Medium": "Quilon/Quilon-Medium.otf",
    "Quilon-SemiBold": "Quilon/Quilon-SemiBold.otf",
    "Quilon-Bold": "Quilon/Quilon-Bold.otf",

    "RadioCanadaBig-Regular": "RadioCanadaBig/RadioCanadaBig-Regular.ttf",
    "RadioCanadaBig-Italic": "RadioCanadaBig/RadioCanadaBig-Italic.ttf",
    "RadioCanadaBig-Medium": "RadioCanadaBig/RadioCanadaBig-Medium.ttf",
    "RadioCanadaBig-MediumItalic": "RadioCanadaBig/RadioCanadaBig-MediumItalic.ttf",
    "RadioCanadaBig-SemiBold": "RadioCanadaBig/RadioCanadaBig-SemiBold.ttf",
    "RadioCanadaBig-SemiBoldItalic": "RadioCanadaBig/RadioCanadaBig-SemiBoldItalic.ttf",
    "RadioCanadaBig-Bold": "RadioCanadaBig/RadioCanadaBig-Bold.ttf",
    "RadioCanadaBig-BoldItalic": "RadioCanadaBig/RadioCanadaBig-BoldItalic.ttf",

    "Satoshi-Light": "Satoshi/Satoshi-Light.otf",
    "Satoshi-LightItalic": "Satoshi/Satoshi-LightItalic.otf",
    "Satoshi-Regular": "Satoshi/Satoshi-Regular.otf",
    "Satoshi-Italic": "Satoshi/Satoshi-Italic.otf",
    "Satoshi-Medium": "Satoshi/Satoshi-Medium.otf",
    "Satoshi-MediumItalic": "Satoshi/Satoshi-MediumItalic.otf",
    "Satoshi-Bold": "Satoshi/Satoshi-Bold.otf",
    "Satoshi-BoldItalic": "Satoshi/Satoshi-BoldItalic.otf",
    "Satoshi-Black": "Satoshi/Satoshi-Black.otf",
    "Satoshi-BlackItalic": "Satoshi/Satoshi-BlackItalic.otf",

    "WorkSans-ExtraLight": "WorkSans/WorkSans-ExtraLight.otf",
    "WorkSans-ExtraLightItalic": "WorkSans/WorkSans-ExtraLightItalic.otf",
    "WorkSans-Light": "WorkSans/WorkSans-Light.otf",
    "WorkSans-LightItalic": "WorkSans/WorkSans-LightItalic.otf",
    "WorkSans-Regular": "WorkSans/WorkSans-Regular.otf",
    "WorkSans-Italic": "WorkSans/WorkSans-Italic.otf",
    "WorkSans-Medium": "WorkSans/WorkSans-Medium.otf",
    "WorkSans-MediumItalic": "WorkSans/WorkSans-MediumItalic.otf",
    "WorkSans-SemiBold": "WorkSans/WorkSans-SemiBold.otf",
    "WorkSans-SemiBoldItalic": "WorkSans/WorkSans-SemiBoldItalic.otf",
    "WorkSans-Bold": "WorkSans/WorkSans-Bold.otf",
    "WorkSans-BoldItalic": "WorkSans/WorkSans-BoldItalic.otf",
    "WorkSans-ExtraBold": "WorkSans/WorkSans-ExtraBold.otf",
    "WorkSans-ExtraBoldItalic": "WorkSans/WorkSans-ExtraBoldItalic.otf",
    "WorkSans-Black": "WorkSans/WorkSans-Black.otf",
    "WorkSans-BlackItalic": "WorkSans/WorkSans-BlackItalic.otf",

    "Zodiak-Thin": "Zodiak/Zodiak-Thin.otf",
    "Zodiak-ThinItalic": "Zodiak/Zodiak-ThinItalic.otf",
    "Zodiak-Light": "Zodiak/Zodiak-Light.otf",
    "Zodiak-LightItalic": "Zodiak/Zodiak-LightItalic.otf",
    "Zodiak-Regular": "Zodiak/Zodiak-Regular.otf",
    "Zodiak-Italic": "Zodiak/Zodiak-Italic.otf",
    "Zodiak-Bold": "Zodiak/Zodiak-Bold.otf",
    "Zodiak-BoldItalic": "Zodiak/Zodiak-BoldItalic.otf",
    "Zodiak-Black": "Zodiak/Zodiak-Black.otf",
    "Zodiak-BlackItalic": "Zodiak/Zodiak-BlackItalic.otf"
};

const obsoleteBuiltInFontIds = [
    "sourcesanspro-header-heavy",
    "sourcesanspro-header-light",
    "jost-header-heavy",
    "jost-header-light",
    "bebasneue-header-heavy",
    "bebasneue-header-light",
    "lato-header-heavy",
    "lato-header-light",
    "trocchi-header-heavy",
    "poppins-header-heavy",
    "poppins-header-light",
    "spacemono-header-heavy",
    "spacemono-header-light",
    "playfair-header-heavy",
    "playfair-header-light",
    "raleway-header-heavy",
    "raleway-header-light",
    "montserrat-header-heavy",
    "montserrat-header-light",
    "roboto-header-heavy",
    "roboto-header-light",
    "robotoslab-header-heavy",
    "robotoslab-header-light",
    "sourcesanspro-title-heavy",
    "sourcesanspro-title-light",
    "jost-title-heavy",
    "jost-title-light",
    "lato-title-light",
    "lato-title-heavy",
    "trocchi-title-heavy",
    "poppins-title-light",
    "poppins-title-heavy",
    "spacemono-title-light",
    "spacemono-title-heavy",
    "bebasneue-title-heavy",
    "bebasneue-title-light",
    "playfair-title-heavy",
    "playfair-title-light",
    "raleway-title-heavy",
    "raleway-title-light",
    "montserrat-title-heavy",
    "montserrat-title-light",
    "roboto-title-heavy",
    "roboto-title-light",
    "robotoslab-title-heavy",
    "robotoslab-title-light",
    "sourcesanspro-body",
    "jost-body",
    "poppins-body",
    "spacemono-body",
    "raleway-body",
    "montserrat-body",
    "roboto-body",
    "robotoslab-body",
    "lato-body"
];

const CJK = {
    jp: "Noto/NotoSansCJKjp",
    kr: "Noto/NotoSansCJKkr",
    sc: "Noto/NotoSansCJKsc",
    tc: "Noto/NotoSansCJKtc"
};

const fallbackRanges = {
    "0": {
        end: "001F",
        name: "Control character",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "20": {
        end: "007F",
        name: "Basic Latin",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "80": {
        end: "00FF",
        name: "Latin-1 Supplement",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "100": {
        end: "017F",
        name: "Latin Extended-A",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "180": {
        end: "024F",
        name: "Latin Extended-B",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "250": {
        end: "02AF",
        name: "IPA Extensions",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "2B0": {
        end: "02FF",
        name: "Spacing Modifier Letters",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "370": {
        end: "03FF",
        name: "Greek and Coptic",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "400": {
        end: "04FF",
        name: "Cyrillic",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "500": {
        end: "052F",
        name: "Cyrillic Supplement",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "530": {
        end: "058F",
        name: "Armenian",
        hasBold: true,
        path: "Noto/NotoSansArmenian"
    },
    "590": {
        end: "05FF",
        name: "Hebrew",
        hasBold: true,
        path: "Noto/NotoSansHebrew"
    },
    "600": {
        end: "06FF",
        name: "Arabic",
        hasBold: true,
        path: "Noto/NotoSansArabic"
    },
    "700": { end: "074F", name: "Syriac", path: "Noto/NotoSansSyriacEastern" },
    "750": {
        end: "077F",
        name: "Arabic Supplement",
        hasBold: true,
        path: "Noto/NotoSansArabic"
    },
    "780": {
        end: "07BF",
        name: "Thaana",
        hasBold: true,
        path: "Noto/NotoSansThaana"
    },
    "7C0": { end: "07FF", name: "NKo", path: "Noto/NotoSansNKo" },
    "800": { end: "083F", name: "Samaritan", path: "Noto/NotoSansSamaritan" },
    "840": { end: "085F", name: "Mandaic", path: "Noto/NotoSansMandaic" },
    "8A0": {
        end: "08FF",
        name: "Arabic Extended-A",
        hasBold: true,
        path: "Noto/NotoSansArabic"
    },
    "900": {
        end: "097F",
        name: "Devanagari",
        hasBold: true,
        path: "Noto/NotoSansDevanagari"
    },
    "980": {
        end: "09FF",
        name: "Bengali",
        hasBold: true,
        path: "Noto/NotoSansBengali"
    },
    A00: {
        end: "0A7F",
        name: "Gurmukhi",
        hasBold: true,
        path: "Noto/NotoSansGurmukhi"
    },
    A80: {
        end: "0AFF",
        name: "Gujarati",
        hasBold: true,
        path: "Noto/NotoSansGujarati"
    },
    B00: {
        end: "0B7F",
        name: "Oriya",
        hasBold: true,
        path: "Noto/NotoSansOriya"
    },
    B80: {
        end: "0BFF",
        name: "Tamil",
        hasBold: true,
        path: "Noto/NotoSansTamil"
    },
    C00: {
        end: "0C7F",
        name: "Telugu",
        hasBold: true,
        path: "Noto/NotoSansTelugu"
    },
    C80: {
        end: "0CFF",
        name: "Kannada",
        hasBold: true,
        path: "Noto/NotoSansKannada"
    },
    D00: {
        end: "0D7F",
        name: "Malayalam",
        hasBold: true,
        path: "Noto/NotoSansMalayalam"
    },
    D80: {
        end: "0DFF",
        name: "Sinhala",
        hasBold: true,
        path: "Noto/NotoSansSinhala"
    },
    E00: {
        end: "0E7F",
        name: "Thai",
        hasBold: true,
        path: "Noto/NotoSansThai"
    },
    E80: { end: "0EFF", name: "Lao", hasBold: true, path: "Noto/NotoSansLao" },
    F00: {
        end: "0FFF",
        name: "Tibetan",
        hasBold: true,
        path: "Noto/NotoSansTibetan"
    },
    "1000": {
        end: "109F",
        name: "Myanmar",
        hasBold: true,
        path: "Noto/NotoSansMyanmar"
    },
    "10A0": {
        end: "10FF",
        name: "Georgian",
        hasBold: true,
        path: "Noto/NotoSansGeorgian"
    },
    "1100": {
        end: "11FF",
        name: "Hangul Jamo",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "1200": {
        end: "137F",
        name: "Ethiopic",
        hasBold: true,
        path: "Noto/NotoSansEthiopic"
    },
    "1380": {
        end: "139F",
        name: "Ethiopic Supplement",
        hasBold: true,
        path: "Noto/NotoSansEthiopic"
    },
    "13A0": {
        end: "13FF",
        name: "Cherokee",
        hasBold: true,
        path: "Noto/NotoSansCherokee"
    },
    "1400": {
        end: "167F",
        name: "Unified Canadian Aboriginal Syllabics",
        path: "Noto/NotoSansCanadianAboriginal"
    },
    "1680": { end: "169F", name: "Ogham", path: "Noto/NotoSansOgham" },
    "16A0": { end: "16FF", name: "Runic", path: "Noto/NotoSansRunic" },
    "1700": { end: "171F", name: "Tagalog", path: "Noto/NotoSansTagalog" },
    "1720": { end: "173F", name: "Hanunoo", path: "Noto/NotoSansHanunoo" },
    "1740": { end: "175F", name: "Buhid", path: "Noto/NotoSansBuhid" },
    "1760": { end: "177F", name: "Tagbanwa", path: "Noto/NotoSansTagbanwa" },
    "1780": {
        end: "17FF",
        name: "Khmer",
        hasBold: true,
        path: "Noto/NotoSansKhmer"
    },
    "1800": { end: "18AF", name: "Mongolian", path: "Noto/NotoSansMongolian" },
    "18B0": {
        end: "18FF",
        name: "Unified Canadian Aboriginal Syllabics Extended",
        path: "Noto/NotoSansCanadianAboriginal"
    },
    "1900": { end: "194F", name: "Limbu", path: "Noto/NotoSansLimbu" },
    "1950": { end: "197F", name: "Tai Le", path: "Noto/NotoSansTaiLe" },
    "1980": {
        end: "19DF",
        name: "New Tai Lue",
        path: "Noto/NotoSansNewTaiLue"
    },
    "19E0": {
        end: "19FF",
        name: "Khmer Symbols",
        hasBold: true,
        path: "Noto/NotoSansKhmer"
    },
    "1A00": { end: "1A1F", name: "Buginese", path: "Noto/NotoSansBuginese" },
    "1A20": { end: "1AAF", name: "Tai Tham", path: "Noto/NotoSansTaiTham" },
    "1B00": { end: "1B7F", name: "Balinese", path: "Noto/NotoSansBalinese" },
    "1B80": { end: "1BBF", name: "Sundanese", path: "Noto/NotoSansSundanese" },
    "1BC0": { end: "1BFF", name: "Batak", path: "Noto/NotoSansBatak" },
    "1C00": { end: "1C4F", name: "Lepcha", path: "Noto/NotoSansLepcha" },
    "1C50": { end: "1C7F", name: "Ol Chiki", path: "Noto/NotoSansOlChiki" },
    "1C80": {
        end: "1C8F",
        name: "Cyrillic Extended C",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "1CC0": {
        end: "1CCF",
        name: "Sundanese Supplement",
        path: "Noto/NotoSansSundanese"
    },
    "1CD0": {
        end: "1CFF",
        name: "Vedic Extensions",
        hasBold: true,
        path: "Noto/NotoSansDevanagari"
    },
    "1D00": {
        end: "1D7F",
        name: "Phonetic Extensions",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "1D80": {
        end: "1DBF",
        name: "Phonetic Extensions Supplement",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "1E00": {
        end: "1EFF",
        name: "Latin Extended Additional",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "1F00": {
        end: "1FFF",
        name: "Greek Extended",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "2000": {
        end: "206F",
        name: "General Punctuation",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "2070": {
        end: "209F",
        name: "Superscripts and Subscripts",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "20A0": {
        end: "20CF",
        name: "Currency Symbols",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "2100": {
        end: "214F",
        name: "Letterlike Symbols",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "2300": {
        end: "23FF",
        name: "Miscellaneous Technical",
        hasBold: true,
        path: "Noto/NotoSansSymbols"
    },
    "2400": {
        end: "243F",
        name: "Control Pictures",
        path: "Noto/NotoSansSymbols2"
    },
    "2460": {
        end: "24FF",
        name: "Enclosed Alphanumerics",
        hasBold: true,
        path: "Noto/NotoSansSymbols"
    },
    "2500": {
        end: "257F",
        name: "Box Drawing",
        hasBold: true,
        path: "Noto/NotoSansMono"
    },
    "2580": {
        end: "259F",
        name: "Block Elements",
        hasBold: true,
        path: "Noto/NotoSansMono"
    },
    "2600": {
        end: "26FF",
        name: "Miscellaneous Symbols",
        hasBold: true,
        path: "Noto/NotoSansSymbols"
    },
    "2800": {
        end: "28FF",
        name: "Braille Patterns",
        path: "Noto/NotoSansSymbols2"
    },
    "2B00": {
        end: "2BFF",
        name: "Miscellaneous Symbols and Arrows",
        path: "Noto/NotoSansSymbols2"
    },
    "2C00": {
        end: "2C5F",
        name: "Glagolitic",
        path: "Noto/NotoSansGlagolitic"
    },
    "2C60": {
        end: "2C7F",
        name: "Latin Extended-C",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "2C80": { end: "2CFF", name: "Coptic", path: "Noto/NotoSansCoptic" },
    "2D00": {
        end: "2D2F",
        name: "Georgian Supplement",
        hasBold: true,
        path: "Noto/NotoSansGeorgian"
    },
    "2D30": { end: "2D7F", name: "Tifinagh", path: "Noto/NotoSansTifinagh" },
    "2D80": {
        end: "2DDF",
        name: "Ethiopic Extended",
        hasBold: true,
        path: "Noto/NotoSansEthiopic"
    },
    "2E00": {
        end: "2E7F",
        name: "Supplemental Punctuation",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    "2E80": {
        end: "2EFF",
        name: "CJK Radicals Supplement",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "2F00": {
        end: "2FDF",
        name: "Kangxi Radicals",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    // "2FF0": {end: "2FFF", name: "Ideographic Description Characters", hasBold: true, path: "Noto/NotoSans"},
    "3000": {
        end: "303F",
        name: "CJK Symbols and Punctuation",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "3040": {
        end: "309F",
        name: "Hiragana",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "30A0": {
        end: "30FF",
        name: "Katakana",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "3100": {
        end: "312F",
        name: "Bopomofo",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "3130": {
        end: "318F",
        name: "Hangul Compatibility Jamo",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "3190": {
        end: "319F",
        name: "Kanbun",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "31A0": {
        end: "31BF",
        name: "Bopomofo Extended",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "31C0": {
        end: "31EF",
        name: "CJK Strokes",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "31F0": {
        end: "31FF",
        name: "Katakana Phonetic Extensions",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "3200": {
        end: "32FF",
        name: "Enclosed CJK Letters and Months",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "3300": {
        end: "33FF",
        name: "CJK Compatibility",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "3400": {
        end: "4DBF",
        name: "CJK Unified Ideographs Extension A",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "4DC0": {
        end: "4DFF",
        name: "Yijing Hexagram Symbols",
        path: "Noto/NotoSansSymbols2"
    },
    "4E00": {
        end: "9FFF",
        name: "CJK Unified Ideographs",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    A000: { end: "A48F", name: "Yi Syllables", path: "Noto/NotoSansYi" },
    A490: { end: "A4CF", name: "Yi Radicals", path: "Noto/NotoSansYi" },
    A4D0: { end: "A4FF", name: "Lisu", path: "Noto/NotoSansLisu" },
    A500: { end: "A63F", name: "Vai", path: "Noto/NotoSansVai" },
    A640: {
        end: "A69F",
        name: "Cyrillic Extended-B",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    A6A0: { end: "A6FF", name: "Bamum", path: "Noto/NotoSansBamum" },
    A700: {
        end: "A71F",
        name: "Modifier Tone Letters",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    A720: {
        end: "A7FF",
        name: "Latin Extended-D",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    A800: {
        end: "A82F",
        name: "Syloti Nagri",
        path: "Noto/NotoSansSylotiNagri"
    },
    A830: {
        end: "A83F",
        name: "Common Indic Number Forms",
        hasBold: true,
        path: "Noto/NotoSansDevanagari"
    },
    A840: { end: "A87F", name: "Phags-pa", path: "Noto/NotoSansPhagsPa" },
    A880: { end: "A8DF", name: "Saurashtra", path: "Noto/NotoSansSaurashtra" },
    A8E0: {
        end: "A8FF",
        name: "Devanagari Extended",
        hasBold: true,
        path: "Noto/NotoSansDevanagari"
    },
    A900: { end: "A92F", name: "Kayah Li", path: "Noto/NotoSansKayahLi" },
    A930: { end: "A95F", name: "Rejang", path: "Noto/NotoSansRejang" },
    A960: {
        end: "A97F",
        name: "Hangul Jamo Extended-A",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    A980: { end: "A9DF", name: "Javanese", path: "Noto/NotoSansJavanese" },
    A9E0: {
        end: "A9FF",
        name: "Myanmar Extended-B",
        hasBold: true,
        path: "Noto/NotoSansMyanmar"
    },
    AA00: {
        end: "AA5F",
        name: "Cham",
        hasBold: true,
        path: "Noto/NotoSansCham"
    },
    AA60: {
        end: "AA7F",
        name: "Myanmar Extended-A",
        hasBold: true,
        path: "Noto/NotoSansMyanmar"
    },
    AA80: { end: "AADF", name: "Tai Viet", path: "Noto/NotoSansTaiViet" },
    AAE0: {
        end: "AAFF",
        name: "Meetei Mayek Extensions",
        path: "Noto/NotoSansMeeteiMayek"
    },
    AB00: {
        end: "AB2F",
        name: "Ethiopic Extended-A",
        hasBold: true,
        path: "Noto/NotoSansEthiopic"
    },
    AB30: {
        end: "AB6F",
        name: "Latin Extended-E",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    AB70: {
        end: "ABBF",
        name: "Cherokee Supplement",
        hasBold: true,
        path: "Noto/NotoSansCherokee"
    },
    ABC0: {
        end: "ABFF",
        name: "Meetei Mayek",
        path: "Noto/NotoSansMeeteiMayek"
    },
    AC00: {
        end: "D7AF",
        name: "Hangul Syllables",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    D7B0: {
        end: "D7FF",
        name: "Hangul Jamo Extended-B",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    F900: {
        end: "FAFF",
        name: "CJK Compatibility Ideographs",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    FB00: {
        end: "FB4F",
        name: "Alphabetic Presentation Forms",
        hasBold: true,
        path: "Noto/NotoSans"
    },
    FB50: {
        end: "FDFF",
        name: "Arabic Presentation Forms-A",
        hasBold: true,
        path: "Noto/NotoSansArabic"
    },
    FE10: {
        end: "FE1F",
        name: "Vertical Forms",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    FE30: {
        end: "FE4F",
        name: "CJK Compatibility Forms",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    FE50: {
        end: "FE6F",
        name: "Small Form Variants",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    FE70: {
        end: "FEFF",
        name: "Arabic Presentation Forms-B",
        hasBold: true,
        path: "Noto/NotoSansArabic"
    },
    FF00: {
        end: "FFEF",
        name: "Halfwidth and Fullwidth Forms",
        path: CJK,
        hasBold: true,
        isCJK: true
    },
    "10000": { end: true }
};

const rangeStartDecToHexMap = {};
const fallbackRangeStartsDecimal = Object.keys(fallbackRanges)
    .map(hex => {
        let int = parseInt(hex, 16);
        rangeStartDecToHexMap[int] = hex;
        return int;
    })
    .sort((a, b) => a - b);

// find the start of the unicode range containing n.
function calculateFallbackRangeStart(n) {
    for (let i = 0; i < fallbackRangeStartsDecimal.length; i++) {
        if (
            n >= fallbackRangeStartsDecimal[i] &&
            n < fallbackRangeStartsDecimal[i + 1]
        ) {
            return rangeStartDecToHexMap[fallbackRangeStartsDecimal[i]];
        }
    }
    new Error(`No range for ${n}`);
}

function getFontWeightName(weight) {
    switch (weight) {
        case 100:
            return "Thin";
        case 200:
            return "Extra Light";
        case 300:
            return "Light";
        case 400:
            return "Regular";
        case 500:
            return "Medium";
        case 600:
            return "Semi Bold";
        case 700:
            return "Bold";
        case 800:
            return "Extra Bold";
        case 900:
            return "Black";
        default:
            return "Unknown";
    }
}

const builtInFontExportPackagesMap = {
    "trocchi": "Trocchi",
    "bebasneue": "BebasNeue",
    "jost": "Jost",
    "lato": "Lato",
    "marker": "Marker",
    "montserrat": "Montserrat",
    "playfairdisplay": "PlayfairDisplay",
    "poppins": "Poppins",
    "raleway": "Raleway",
    "roboto": "Roboto",
    "robotoslab": "RobotoSlab",
    "sourcesanspro": "SourceSansPro",
    "spacemono": "SpaceMono",
    "carlito": "Carlito",
    "archivo": "Archivo",
    "bespokeslab": "BespokeSlab",
    "bigshouldersdisplay": "BigShouldersDisplay",
    "bitter": "Bitter",
    "bricolagegrotesque": "BricolageGrotesque",
    "catamaran": "Catamaran",
    "clashdisplay": "ClashDisplay",
    "cormorant": "Cormorant",
    "darkergrotesque": "DarkerGrotesque",
    "delagothicone": "DelaGothicOne",
    "dmmono": "DMMono",
    "dmsans": "DMSans",
    "domine": "Domine",
    "eduvicwantbeginner": "EduVICWANTBeginner",
    "epilogue": "Epilogue",
    "gayathri": "Gayathri",
    "geist": "Geist",
    "generalsans": "GeneralSans",
    "glegoo": "Glegoo",
    "ibmplexsans": "IBMPlexSans",
    "ibmplexsanscondensed": "IBMPlexSansCondensed",
    "instrumentsans": "InstrumentSans",
    "inter": "Inter",
    "lexend": "Lexend",
    "literata": "Literata",
    "mada": "Mada",
    "merriweathersans": "MerriweatherSans",
    "notosans": "NotoSans",
    "nunitosans": "NunitoSans",
    "opensans": "OpenSans",
    "pathwayextreme": "PathwayExtreme",
    "plein": "Plein",
    "quilon": "Quilon",
    "radiocanadabig": "RadioCanadaBig",
    "satoshi": "Satoshi",
    "worksans": "WorkSans",
    "zodiak": "Zodiak"
};

module.exports = {
    builtInFontFiles,
    fallbackRanges,
    builtInFonts,
    cjkFallbackFonts,
    builtInFontExportPackagesMap,
    obsoleteBuiltInFontIds,
    calculateFallbackRangeStart,
    getFontWeightName
};
