const theme = {
    isBuiltIn: true,
    id: "luminary",
    name: "Luminary",
    backgroundGradients: [
        {
            angle: 180,
            colorStyle: "color",
            id: "a0653386-ac91-44a7-be0b-af0eb687e475",
            noise: 0,
            stops: [
                {
                    color: "#9911FF",
                    position: 0
                },
                {
                    color: "#DD00EE",
                    position: 100
                }
            ],
            type: "linear"
        },
        {
            angle: 0,
            colorStyle: "color",
            id: "e806d406-3698-4c24-ae07-84b7ac519c47",
            noise: 0,
            stops: [
                {
                    color: "#FF0088",
                    position: 30
                },
                {
                    color: "#DD00EE",
                    position: 100
                }
            ],
            type: "linear"
        }
    ],
    cjkFont: "jp",
    colors: {
        accent1: "rgba(153,17,255,1)",
        accent2: "rgba(119,0,255,1)",
        accent3: "rgba(51,0,255,1)",
        accent4: "rgba(0,119,255,1)",
        background_accent: "rgba(242,242,242,1)",
        background_dark: "rgba(10,10,10,1)",
        background_light: "#ffffff",
        chart1: "rgba(0,119,255,1)",
        chart2: "rgba(51,0,255,1)",
        chart3: "rgba(119,0,255,1)",
        chart4: "rgba(153,17,255,1)",
        chart5: "rgba(221,0,238,1)",
        hyperlink: "rgba(204,136,255,1)",
        negative: "rgba(252,60,39,1)",
        positive: "rgba(118,214,44,1)",
        primary_dark: "rgba(51,51,51,1)",
        primary_light: "rgba(255,255,255,1)",
        secondary_dark: "rgba(0,0,0,0.55)",
        secondary_light: "rgba(255,255,255,0.8)",
        theme: "rgba(221,0,238,1)"
    },
    defaultBackgroundColor: "background_light",
    defaultSlideColor: "theme",
    fontBodyFontId: "epilogue",
    fontBodyLetterSpacing: -0.02,
    fontBodyLineHeight: 1.8,
    fontBodyScaling: 100,
    fontBodyTextTransform: "auto",
    fontBodyWeight: 400,
    fontHeaderFontId: "epilogue",
    fontHeaderLetterSpacing: -0.06,
    fontHeaderLineHeight: 1.4,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontHeaderWeight: 600,
    fontScale: 1,
    fontTitleFontId: "epilogue",
    fontTitleLetterSpacing: -0.05,
    fontTitleLineHeight: 1.4,
    fontTitleScaling: 100,
    fontTitleTextTransform: "auto",
    fontTitleWeight: 600,
    footerMessage: `${new Date().getFullYear()} Proprietary and Confidential. All Rights Reserved.`,
    iconStyle: "classic",
    isRTL: false,
    logoOffset: 0,
    logoPosition: "left",
    logoScale: 1,
    palette_name: "custom",
    showFooterByDefault: true,
    showLogo: true,
    showMessage: false,
    showPageNum: true,
    styleBackground: "none",
    styleBodyFont: "sourcesanspro",
    styleDecoration: "none_center",
    styleDesign: 1,
    styleEffect: "none",
    styleElementStyle: "filled",
    styleFontWeight: "heavy",
    styleShape: "circle",
    styleTitleFont: "bebasneue",
    styleTitleSlide: "bar_left",
    styleWeight: "light"
};

module.exports = theme;
