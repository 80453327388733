const theme = {
    isBuiltIn: true,
    id: "discovery",
    name: "Discovery",
    showLogo: true,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: false,
    showPageNum: true,
    showFooterByDefault: true,
    footerMessage: `${new Date().getFullYear()} Proprietary and Confidential. All Rights Reserved.`,
    isRTL: false,
    palette_name: "custom",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_light",
    styleFontWeight: "heavy",
    styleElementStyle: "filled",
    styleEffect: "none",
    styleDesign: 1,
    styleDecoration: "none_center",
    styleShape: "circle",
    styleBackground: "none",
    styleTitleFont: "bebasneue",
    styleBodyFont: "sourcesanspro",
    styleWeight: "meduim",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "classic",
    cjkFont: "jp",
    colors: {
        accent1: "rgba(57,216,193,1)",
        accent2: "rgba(56,216,230,1)",
        accent3: "rgba(48,175,239,1)",
        accent4: "rgba(107,162,255,1)",
        accent5: "rgba(147,155,241,1)",
        background_accent: "rgba(242,242,242,1)",
        background_dark: "rgba(0,0,0,1)",
        background_light: "#ffffff",
        chart1: "rgba(72,220,146,1)",
        chart2: "rgba(57,216,193,1)",
        chart3: "rgba(56,216,230,1)",
        chart4: "rgba(48,175,239,1)",
        chart5: "rgba(107,162,255,1)",
        chart6: "rgba(147,155,241,1)",
        negative: "rgba(252,93,76,1)",
        positive: "rgba(72,220,146,1)",
        primary_dark: "rgba(51,51,51,1)",
        primary_light: "rgba(255,255,255,1)",
        secondary_dark: "rgba(0,0,0,0.65)",
        secondary_light: "rgba(255,255,255,0.8)",
        theme: "rgba(72,220,146,1)",
        hyperlink: "#11a9e2"
    },
    fontHeaderFontId: "geist",
    fontHeaderWeight: 600,
    fontHeaderLetterSpacing: -0.06,
    fontHeaderLineHeight: 1.4,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontTitleFontId: "geist",
    fontTitleWeight: 600,
    fontTitleLetterSpacing: -0.03,
    fontTitleLineHeight: 1.6,
    fontTitleScaling: 100,
    fontTitleTextTransform: "auto",
    fontBodyFontId: "geist",
    fontBodyWeight: 400,
    fontBodyLetterSpacing: -0.04,
    fontBodyLineHeight: 1.9,
    fontBodyScaling: 100,
    fontBodyTextTransform: "auto",
};

module.exports = theme;
