import type { EndpointDefinition } from "../_sdk/types/common";

export const apiName = "presentations";

export const endpoints: { [key: string]: EndpointDefinition } = {
    getPresentation: {
        method: "GET",
        route: "/presentations/:id",
    },
    createPresentation: {
        method: "POST",
        route: "/presentations",
    },
    updatePresentation: {
        method: "PUT",
        route: "/presentations/:id",
    },
    deletePresentation: {
        method: "DELETE",
        route: "/presentations/:id",
    },

    copyPresentation: {
        method: "PUT",
        route: "/presentations/:id/copy",
    },
    moveWorkspace: {
        method: "PUT",
        route: "/presentations/:id/move",
    },
    recordActivity: {
        method: "PUT",
        route: "/presentations/:id/activity",
    },

    getSlidesMetadata: {
        method: "GET",
        route: "/presentations/:id/slidesMetadata",
    },
    updateSlidesMetadata: {
        method: "PUT",
        route: "/presentations/:id/slidesMetadata",
    },

    createLink: {
        method: "POST",
        route: "/presentations/:id/links",
    },
    updateLinkPassword: {
        method: "PUT",
        route: "/presentations/:id/links/:linkId/password",
    },
    connectEmbedLinkDomains: {
        method: "PUT",
        route: "/presentations/:id/links/:linkId/embedLinkDomains"
    },
    incrementLinkViewCount: {
        method: "PUT",
        route: "/presentations/:id/links/:linkId/viewCount",
    },

    getPresentationsMetadata: {
        method: "POST",
        route: "/presentations/metadata",
    },

    getSlideCount: {
        method: "POST",
        route: "/presentations/slideCount",
    },
};
