const block = require("./block");
const bold = require("./bold");
const dark = require("./dark");
const museum = require("./museum");
const inc = require("./inc");
const magazine = require("./magazine");
const minimal = require("./minimal");
const cheeky = require("./cheeky");
const space = require("./space");
const classic = require("./classic");
const ppt = require("./ppt");
const simple = require("./simple");
const discovery = require("./discovery");
const horizon = require("./horizon");
const luminary = require("./luminary");
const ember = require("./ember");
const spritz = require("./spritz");
const daybreak = require("./daybreak");
const { cloneDeep } = require("lodash");

const THEME_ATTRIBUTES_MAPPING = [
    ["theme_logo", "logo"],
    ["theme_logo_dark", "logo_dark"],
    ["theme_showLogo", "showLogo"],
    ["theme_logoPosition", "logoPosition"],
    ["theme_logoOffset", "logoOffset"],
    ["theme_logoScale", "logoScale"],
    ["theme_showMessage", "showMessage"],
    ["theme_showPageNum", "showPageNum"],
    ["theme_showFooterByDefault", "showFooterByDefault"],
    ["theme_footerMessage", "footerMessage"],
    ["theme_palette_name", "palette_name"],
    ["theme_defaultSlideColor", "defaultSlideColor"],
    ["theme_defaultBackgroundColor", "defaultBackgroundColor"],
    ["theme_styleWeight", "styleWeight"],
    ["theme_styleElementStyle", "styleElementStyle"],
    ["theme_styleTitleFont", "styleTitleFont"],
    ["theme_styleBodyFont", "styleBodyFont"],
    ["theme_styleFontWeight", "styleFontWeight"],
    ["theme_styleEffect", "styleEffect"],
    ["theme_styleDesign", "styleDesign"],
    ["theme_styleShape", "styleShape"],
    ["theme_styleWeight", "styleWeight"],
    ["theme_styleBackground", "styleBackground"],
    ["theme_styleDecoration", "styleDecoration"],
    ["theme_styleTitleSlide", "styleTitleSlide"],
    ["theme_colors", "colors"],
    ["theme_iconStyle", "iconStyle"],
    ["theme_fontScale", "fontScale"],
    ["theme_cjkFont", "cjkFont"],
    ["theme_isRTL", "isRTL"],

    ["theme_fontHeaderFontId", "fontHeaderFontId"],
    ["theme_fontHeaderWeight", "fontHeaderWeight"],
    ["theme_fontHeaderBoldWeight", "fontHeaderBoldWeight"],
    ["theme_fontHeaderLetterSpacing", "fontHeaderLetterSpacing"],
    ["theme_fontHeaderLineHeight", "fontHeaderLineHeight"],
    ["theme_fontHeaderScaling", "fontHeaderScaling"],
    ["theme_fontHeaderTextTransform", "fontHeaderTextTransform"],

    ["theme_fontTitleFontId", "fontTitleFontId"],
    ["theme_fontTitleWeight", "fontTitleWeight"],
    ["theme_fontTitleBoldWeight", "fontTitleBoldWeight"],
    ["theme_fontTitleLetterSpacing", "fontTitleLetterSpacing"],
    ["theme_fontTitleLineHeight", "fontTitleLineHeight"],
    ["theme_fontTitleScaling", "fontTitleScaling"],
    ["theme_fontTitleTextTransform", "fontTitleTextTransform"],

    ["theme_fontBodyFontId", "fontBodyFontId"],
    ["theme_fontBodyWeight", "fontBodyWeight"],
    ["theme_fontBodyBoldWeight", "fontBodyBoldWeight"],
    ["theme_fontBodyLetterSpacing", "fontBodyLetterSpacing"],
    ["theme_fontBodyLineHeight", "fontBodyLineHeight"],
    ["theme_fontBodyScaling", "fontBodyScaling"],
    ["theme_fontBodyTextTransform", "fontBodyTextTransform"],

    ["theme_chartColors", "chartColors"],
    ["theme_backgroundImages", "backgroundImages"],
    ["theme_backgroundGradients", "backgroundGradients"],

    // Obsolete fields, preserved to allow reusing thumbnails
    // that were generated before fonts refactor (to preserve
    // theme hashes which are generated based on this set of fields)
    ["theme_fontHeader", "fontHeader"],
    ["theme_fontHeaderRegular", "fontHeaderRegular"],
    ["theme_fontHeaderItalic", "fontHeaderItalic"],
    ["theme_fontHeaderEmphasized", "fontHeaderEmphasized"],
    ["theme_fontHeaderEmphasizedItalic", "fontHeaderEmphasizedItalic"],
    ["theme_fontTitle", "fontTitle"],
    ["theme_fontTitleRegular", "fontTitleRegular"],
    ["theme_fontTitleItalic", "fontTitleItalic"],
    ["theme_fontTitleEmphasized", "fontTitleEmphasized"],
    ["theme_fontTitleEmphasizedItalic", "fontTitleEmphasizedItalic"],
    ["theme_fontBody", "fontBody"],
    ["theme_fontBodyRegular", "fontBodyRegular"],
    ["theme_fontBodyItalic", "fontBodyItalic"],
    ["theme_fontBodyEmphasized", "fontBodyEmphasized"],
    ["theme_fontBodyEmphasizedItalic", "fontBodyEmphasizedItalic"],

    ["theme_customStyles", "customStyles"],
    ["theme_lastThemeEditor", "lastThemeEditor"],
    ["theme_customComponents", "customComponents"]
];

const THEME_DEFAULTS = {
    showLogo: true,
    logoScale: 1,
    logoPosition: "left",
    logoOffset: 0,
    showMessage: true,
    showPageNum: true,
    logo: null,
    logo_dark: null,
    showFooterByDefault: null,
    footerMessage: `©${new Date().getFullYear()} Proprietary and Confidential. All Rights Reserved.`,
    palette_name: "colorful",
    defaultSlideColor: "theme",
    defaultBackgroundColor: "background_light",
    styleFonts: 1,
    styleFontWeight: "heavy",
    styleElementStyle: "outlined",
    styleEffect: "none",
    styleDesign: 1,
    styleDecoration: "bar_left",
    styleHeaderAlignment: "left",
    styleShape: "rect",
    styleColor: "slide",
    styleBackground: "none",
    styleTitleFont: "sourcesanspro",
    styleBodyFont: "sourcesanspro",
    styleWeight: "medium",
    styleTitleSlide: "bar_left",
    fontScale: 1,
    iconStyle: "chunky",
    cjkFont: "jp",
    isRTL: false
};

/**
 * Extracts theme fields from a presentation and composes a theme model
 */
function getThemeFromPresentationModel(presentation) {
    // Assigning defaults
    const theme = cloneDeep(THEME_DEFAULTS);
    // Assigning keys present in the presentation
    THEME_ATTRIBUTES_MAPPING.forEach(([presentationKey, themeKey]) => {
        if (presentationKey in presentation) {
            theme[themeKey] = cloneDeep(presentation[presentationKey]);
        }
    });

    return theme;
}

/**
 * Sanitizes a theme model and removes all fields that are not listed in
 * THEME_ATTRIBUTES_MAPPING
 */
function sanitizeTheme(theme) {
    // Assigning defaults
    const sanitizedTheme = cloneDeep(THEME_DEFAULTS);
    // Assigning keys present in the supplied theme
    THEME_ATTRIBUTES_MAPPING.forEach(([presentationKey, themeKey]) => {
        if (themeKey in theme) {
            sanitizedTheme[themeKey] = cloneDeep(theme[themeKey]);
        }
    });

    return sanitizedTheme;
}

const themes = {
    daybreak,
    discovery,
    horizon,
    luminary,
    ember,
    spritz,
    minimal,
    classic,
    bold,
    magazine,
    block,
    inc,
    cheeky,
    museum,
    space,
    dark
};

function getDefaultTheme() {
    return themes.daybreak;
}

module.exports = {
    themes,
    getDefaultTheme,
    defaultPPTTheme: ppt,
    defaultSimpleTheme: simple,
    THEME_DEFAULTS,
    THEME_ATTRIBUTES_MAPPING,
    getThemeFromPresentationModel,
    sanitizeTheme
};
